body,
html {
  overflow-x: hidden !important;
}

.posterBack {
  background-color: #dee8ef;
  width: 100%;
  height: 570px;
  margin-top: 18px;
  position: relative;
}

::-webkit-scrollbar {
  display: none;
}

.rightPoster {
  position: relative;
  bottom: 0px;
  right: 123px;
}

.homeImg1 {
  width: 83%;
  height: 100%;
  position: relative;
  left: 120px;
  top: 15px;
}

.leftPoster {
  position: relative;
  top: 100px;
  left: 120px;
  text-align: left;
}

.discover {
  color: black;
  font-weight: bold;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
}

.cafoundation {
  font-weight: bold;
  line-height: 32px;
  font-size: 28px;
  color: black;
  font-family: "Raleway", sans-serif;
}

.learnAt {
  line-height: 30px;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  color: black;
  position: relative;
  bottom: 15px;
}

.blueBack {
  background-color: #3a519d;
  position: absolute;
  bottom: -88px;
  left: 17%;
  width: 65%;
  height: 18%;
  border-radius: 8px;
}

.showBlueBack {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.requestCallBack {
  background-color: #e2e2e2;
  border: none;
  position: relative;
  top: 20px;
  padding: 7px 12px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.2px;
  font-family: "Raleway", sans-serif;
  text-align: left;
  height: 27pt;
}

.requestPara {
  position: relative;
  top: 28px;
  color: #dbd7d7;
}

.joinCa {
  padding: 51px 0px 0px 0px;

  h2 {
    text-transform: capitalize !important;
  }

  h3 {
    margin-bottom: 0;
    margin-top: 15px;
  }
}

.join {
  font-weight: bold;
  text-align: center;
  font-family: "Raleway", sans-serif;
  color: black;
  font-size: 24px;
  margin: 0;
}

.may2022 {
  color: #077c07;
}

.batchAvail {
  color: #6a6a6a;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  font-family: "Raleway", sans-serif;
  line-height: 35px;
  margin: 0;
}

.english {
  color: #3a519d;
}

.hiden {
  display: none;
}

.rightPoster1 {
  display: none;
}
.notificationWrapper {
  height: 50px;
  padding: 0 8px;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  right: 16px;
  bottom: 16px;
  background-color: #333;
  border-radius: 8px;
  z-index: 9999;
  div {
    margin: 0 !important;
  }
  p {
    margin-bottom: 0;
    color: #fff;
    margin-left: 8px;
  }
}
@media only screen and (min-width: 1200px) {
  .homeImg1 {
    width: 83%;
    height: 53rem;
    position: relative;
    left: 120px;
    top: 15px;
  }

  .cafoundation {
    font-weight: bold;
    line-height: 32px;
    font-size: 25px;
    color: black;
    font-family: "Raleway", sans-serif;
  }

  .requestPara {
    position: relative;
    top: 28px;
    color: #dbd7d7;
    font-size: 12px !important;
  }
}
@media screen and (width: 360px) {
  .blueBackCont {
    margin-top: 42px !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 768px) {
  body {
    overflow-x: hidden !important;
  }

  .wraper1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .leftPoster {
    top: 20px;
    margin-left: -120px;
  }

  .discover {
    color: black;
    font-weight: bold;
    font-size: 11px;
    font-family: "Raleway", sans-serif;
    z-index: 999999;
  }

  .cafoundation {
    font-weight: bold;
    line-height: 24px;
    font-size: 16px;
    color: black;
    font-family: "Raleway", sans-serif;
    z-index: 999999;
  }

  .learnAt {
    line-height: 18px;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    color: black;
    position: relative;
    bottom: 15px;
    z-index: 99;
    width: 200px;
  }

  .rightPoster {
    position: relative;
    bottom: 0px;
    right: 60px;
  }

  .homeImg1 {
    width: 220px;
    height: 420px;
    position: relative;
    // left: 120px;
    top: 15px;
  }

  .blueBack {
    display: none;
  }

  .blueBackCont {
    background-color: #3a519d;
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: space-around;
    margin-top: 100px;
  }

  .hiden {
    display: block;
  }

  .posterBack {
    top: 0px !important;
    // overflow-x: hidden !important;
    background-color: #dee8ef;
    width: 100%;
    height: 420px;
    position: relative;
  }

  .requestCallBack {
    background-color: #d6dcf2;
    border: none;
    position: relative;
    top: 20px;
    padding: 7px 12px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.2px;
    font-family: "Raleway", sans-serif;
    text-align: left;
    height: 27pt;
  }

  .rightPoster {
    display: none;
  }

  .rightPoster1 {
    display: block;
    left: 100px;
    height: 240px;
    width: 240px;
  }

  .joinCa {
    padding: 0px 0px;
  }

  .join {
    text-transform: capitalize;
    width: 33rem;
    margin: 0 auto;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    color: black;
    margin-top: 20px;
  }

  .batchAvail {
    color: #6a6a6a;
    width: 29rem;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    font-family: "Raleway", sans-serif;
    padding: 0px 10px;
    line-height: 2.5rem;
  }

  .english {
    color: #3a519d;
  }

  .wingName1536 {
    display: none;
  }

  .homeImg2 {
    width: 190px;
    height: 280px;
    margin-top: 6px;
    // margin-left: -117px;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    right: 35%;
  }
}
@media screen and (min-width:820px) and (max-width: 1180px) {
  body {
    overflow-x: hidden !important;
  }

  .wraper1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .leftPoster {
    top: 20px;
    margin-left: -120px;
  }

  .discover {
    color: black;
    font-weight: bold;
    font-size: 15px;
    font-family: "Raleway", sans-serif;
    z-index: 999999;
  }

  .cafoundation {
    font-weight: bold;
    line-height: 32px;
    font-size: 24px;
    color: black;
    font-family: "Raleway", sans-serif;
    z-index: 999999;
  }

  .learnAt {
    line-height: 18px;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    color: black;
    position: relative;
    bottom: 15px;
    z-index: 99;
  }

  .rightPoster {
    position: relative;
    bottom: 0px;
    right: 60px;
  }

  .homeImg1 {
    width: 220px;
    height: 420px;
    position: relative;
    // left: 120px;
    top: 15px;
  }

  .blueBack {
    display: none;
  }

  .blueBackCont {
    background-color: #3a519d;
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: space-around;
    margin-top: 100px;
  }

  .hiden {
    display: block;
  }

  .posterBack {
    top: 0px !important;
    // overflow-x: hidden !important;
    background-color: #dee8ef;
    width: 100%;
    height: 420px;
    position: relative;
  }

  .requestCallBack {
    background-color: #d6dcf2;
    border: none;
    position: relative;
    top: 20px;
    padding: 7px 12px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.2px;
    font-family: "Raleway", sans-serif;
    text-align: left;
    height: 27pt;
  }

  .rightPoster {
    display: none;
  }

  .rightPoster1 {
    display: block;
    left: 100px;
    height: 240px;
    width: 240px;
  }

  .joinCa {
    padding: 36px 0px;
  }

  .join {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    color: black;
    margin-top: 50px;
  }

  .batchAvail {
    // color: #534f4f;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    font-family: "Raleway", sans-serif;
    padding: 0px 10px;
  }

  .english {
    color: #3a519d;
  }

  .wingName1536 {
    display: none;
  }

  .homeImg2 {
    width: 260px;
    height: 345px;
    margin-top: 6px;
    // margin-left: -117px;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    right: 35%;
  }
}

.intro-section {
  display: flex;
  align-items: center;
  background-color: #fcf6de;
  margin-top: 2rem;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;

    br {
      display: none;
    }
  }

  .intro-left {
    flex: 1 0 45%;
    padding: 0rem 10rem;

    @media screen and (max-width: 768px) {
      // padding: 2rem 2rem;
      position: absolute;
      top: 5%;
      left: -23%;
    }

    strong {
      display: inline-block;
      font-size: 1.8rem;
      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }

    h3 {
      margin: 0;
      margin-top: 2rem;
      font-weight: bold;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        width: 20rem;
        font-size: 1.4rem;
        margin-top: 1rem;
      }
    }

    p {
      margin: 0;
      margin-top: 3rem;
      font-size: 1.8rem;
      width: 40rem;
      @media screen and (max-width: 768px) {
        font-size: 1rem;
        margin-top: 1rem;
        width: 18rem;
      }
    }

    button {
      background-color: #003399;
      color: white;
      border: none;
      border-radius: 0.5rem;
      padding: 1rem 2rem;
      font-family: inherit;
      font-weight: bold;
      margin-top: 5rem;

      @media screen and (max-width: 768px) {
        margin-top: 1rem;
        font-size: 1rem;
      }
    }
  }

  .intro-right {
    flex: 1 0 55%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
