// .row {
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
// }
.pwd-page {
  width: 58%;
  margin: 65px auto;
}
.pwd-page h2 {
  margin-bottom: 25px;
  color: #222;
  margin-top: 50px;
}
h2 {
  font-size: 36px;
  color: #222;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
}

.pwd-page .theme-form input {
  border-color: #ddd;
  font-size: 15px;
  padding: 15px 25px;
  margin-bottom: 15px;
  height: inherit;
  text-align: center;
}
.form-control {
  border-radius: 0;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.webDisplay {
  display: block;
}
.responsiveDisplay {
  display: none;
}
.subjectWrap {
  text-align: center;
  padding: 0px 70px;
}
/* Profile */
.dashboard-right .dashboard {
  border: 1px solid #ddd;
  padding: 30px;
  width: 77%;
  margin-bottom: 20px;
}
.dashboard-right .dashboard .page-title h2 {
  font-size: 22px;
  margin-bottom: 15px;
}
.dashboard-right .dashboard .welcome-msg p {
  margin-bottom: 0;
}
.dashboard-left .block-content a:hover {
  padding-left: 10px;
  transition: all 0.5s ease;
  background: #0f66b9;
  color: #fff;
}
.selectedLink {
  background: #0f66b9;
  color: #fff;
}
.dashboard .box-head h2 {
  font-size: 22px;
  margin: 20px 0 0;
  text-transform: capitalize;
  color: #333;
}
.dashboard .box .box-title {
  border-bottom: 1px solid #ddd;
  display: inline-block;
  margin-bottom: 5px;
  padding: 12px 0;
  position: relative;
  width: 100%;
}
.dashboard .box .box-title h3 {
  font-size: 16px;
  margin: 0;
  text-transform: capitalize;
  color: #333;
}
.dashboard .box h6 {
  margin-bottom: 0;
}
.box-content h6 {
  font-size: 14px;
  line-height: 25px !important;
  margin-bottom: 0;
}
.dashboard .box a {
  color: #00abed;
}
.dashboard-left .block-content {
  padding: 15px;
  background: #ddd;
  height: 475px;
  margin-top: 33px;
}
.dashboradLeftSidebarContents {
  background: #fff;
  padding: 10px;
  color: #da6a06;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: "Nunito", sans-serif;
}
.block-content a {
  display: flex;
  text-decoration: none;
}
/* activation */
.error-section h2 {
  color: #aaa;
  font-weight: 500;
  letter-spacing: 3px;
  margin: 40px 0;
  text-align: center;
}
/* order detail */
.success-text {
  text-align: center;
}
.success-text p {
  font-size: 18px;
  text-transform: capitalize;
}
.light-layout {
  background-color: #f9f9f9;
}
.section-b-space {
  padding-bottom: 70px;
}
.product-order h3 {
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
}
.product-order .product-order-detail {
  margin-top: 15px;
}
.product-order .product-order-detail .order_detail {
  display: flex;
  align-items: center;
}
.product-order .product-order-detail .order_detail h4 {
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 700;
}
.product-order .total-sec {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 15px 0;
  padding: 15px 0;
}
.product-order .total-sec ul {
  padding-left: 0px;
}
.product-order .total-sec ul li {
  font-size: 18px;
  display: block;
  text-transform: capitalize;
  padding: 5px;
}
.product-order .total-sec ul li span {
  float: right;
}
.product-order .final-total h3 {
  display: block;
  margin-bottom: 0;
}
.product-order .final-total h3 span {
  float: right;
}
.order-detail ul {
  padding-left: 0px;
}
.order-success-sec {
  background-color: #f4f1f1;
  padding: 10px;
  border-radius: 5px;
}
.order-success-sec h4 {
  font-weight: 700;
  text-transform: capitalize;
}
.order-success-sec .order-detail li {
  display: flex;
  text-transform: capitalize;
  line-height: 24px;
  font-size: 16px;
}
.order-success-sec .payment-mode p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}
// .pwd-page {
//   text-align: center;
//   width: 70%;
//   margin: -70px auto;
// }
.profileWrap {
  padding: 30px;
}
.editProfile {
  width: 50%;
  margin: 50px auto 50px;
}
.showTestText {
  display: none;
}
.Attempt {
  width: 100%;
  position: relative;
}
.attemptWrap {
  width: 100%;
  /* margin: 50px auto; */
}
.singleAttempt {
  background-color: #008dd0;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  // box-shadow: 6px 5px 10px 4px rgb(0 0 0 / 25%);
  /* width: 352px; */
  height: 90px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.singleAttempt p {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #fff;
  cursor: pointer;
}

.attemptMargin {
  margin: 40px 0px;
}
.attemptArea {
  width: 100%;
  height: 50px;
  background-color: #255992;
  margin: auto;
  display: inline-flex;
  text-align: center;
  margin-top: 50px;
}
.showTestPage {
  display: block;
}
.testSeriesTitle {
  background: #fafbfc;
  border: 1px solid #ddd;
  padding: 14px;
  color: #000;
  margin: 10px;
  position: relative;
}
.responsiveDisplay {
  display: none;
}
.webDisplay {
  display: block;
}
@media only screen and (min-width: 360px) and (max-width: 768px) {
  .error-section h2 {
    color: #aaa;
    font-weight: 500;
    letter-spacing: 3px;
    margin: 0px 0;
    margin-left: 0px;
    font-size: 15px;
    text-align: center;
  }
  .profileWrap {
    padding: 0px;
  }
  .dashboard-right .dashboard {
    border: 1px solid #ddd;
    padding: 30px;
    width: 100%;
    margin-bottom: 20px;
  }
  .editProfile {
    width: 100%;
    margin: 50px auto 50px;
  }
  .pwd-page h2 {
    margin-bottom: 25px;
    color: #222;
    margin-top: 50px;
    font-size: 15px;
  }
  .pwd-page {
    text-align: center;
    width: 100%;
    margin: 0px auto;
  }
  .chamgePassBtn {
    width: 89%;
  }
  .responsiveDisplay {
    display: block;
  }
  .webDisplay {
    display: none;
  }
  .respNav {
    background-color: blue;
    padding: 5px;
    border-radius: 5px;
    width: 35%;
    margin-top: 20px;
  }
  .rightNames {
    position: fixed;
    background-color: #fff;
    width: 367px;
    border-radius: 0;
    border: 1px solid #eee;
    top: 0px;
    right: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll;
    height: 91vh;
    display: block;
    z-index: 999;
  }
  .dashboradLeftSidebarContents {
    background: #fff;
    padding: 10px;
    color: #000;
    font-weight: 400;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .changePassword {
    width: 78%;
  }

  .responsiveDisplay {
    display: none;
  }
  .webDisplay {
    display: block;
  }
  .error-section h2 {
    color: #aaa;
    font-weight: 500;
    letter-spacing: 3px;
    margin: 0px 0;
    margin-left: 0px;
    font-size: 15px;
    text-align: center;
    margin-left: -21%;
  }
  .profileWrap {
    padding: 0px;
  }
  .dashboard-right .dashboard {
    border: 1px solid #ddd;
    padding: 30px;
    width: 76%;
    margin-bottom: 20px;
  }
  .editProfile {
    width: 100%;
    margin: 50px auto 50px;
  }
  .pwd-page h2 {
    margin-bottom: 25px;
    color: #222;
    margin-top: 50px;
    font-size: 15px;
  }
  .pwd-page {
    text-align: center;
    width: 100%;
    margin: 0px auto;
  }
  .chamgePassBtn {
    width: 89%;
  }
  // .responsiveDisplay {
  //   display: block;
  // }
  // .webDisplay {
  //   display: none;
  // }
  .respNav {
    background-color: blue;
    padding: 5px;
    border-radius: 5px;
    width: 35%;
    margin-top: 20px;
  }
  .rightNames {
    position: fixed;
    background-color: #fff;
    width: 367px;
    border-radius: 0;
    border: 1px solid #eee;
    top: 0px;
    right: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll;
    height: 91vh;
    display: block;
    z-index: 999;
  }
  .dashboradLeftSidebarContents {
    background: #fff;
    padding: 7px;
    color: #000;
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 12px;
  }
  .dashboard-left .block-content {
    height: 300px;
  }
  .downloadBtn {
    display: flex;
    margin-left: 20px;
    width: 78px !important;
  }
  .formdashborad {
    margin-left: 70px;
  }
}
@media only screen and (width: 768px) {
  .changePassword {
    width: 78%;
  }

  .responsiveDisplay {
    display: none;
  }
  .webDisplay {
    display: block;
  }
  .dashboradLeftSidebarContents {
    padding: 7px;
  }
  .dashboard-left .block-content {
    height: 400px;
  }
}
