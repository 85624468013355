body{
  font-family:"Roboto", sans-serif;
}
.Web {
  display: block;
  background-color: #f7f7f7;
}

.WebBook {
  display: block;
  background-color: #f8f8f8;
  height: auto;
  padding-bottom: 60px;
}

.Responsive {
  display: none;
}
.ResponsiveBook {
  display: none;
}

.ResponsiveBookNew {
  display: none;
}

.section1 {
  background-image: url("../images/detailsBack.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  .fonts {
    font-size: 35px !important;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    padding: 0px;
    padding-left: 100px;
    color: #fff;
    padding-top: 21px;
    margin-left: 0px;
    margin-bottom: 0px;
    line-height: 51.46px;
    position: relative;
    left: 55px;
    top: 5px;
  }

  .titleName {
    font-weight: 500;
    font-size: 22px;
    text-align: left;
    color: #fff;
    line-height: 30px;
    padding-left: 100px;
    margin-left: 0px;
    margin-top: 0px;
    line-height: 32.34px;
    position: relative;
    left: 55px;
  }
}

.section2 {
  .attemptCourseDetail {
    border-bottom: 1px solid #eeeeee;
    height: auto;
    width: 96%;
  }

  .attemptCourseDetail2 {
    border-bottom: 1px solid #eeeeee;
    font-family: "Roboto", sans-serif;
    height: 60px;
    width: 96%;

    padding-top: 10px;

    .showingDetail {
      font-size: 17px;
      font-weight: 600;
    }

    .batchButtonDetail {
      background-color: #139413;
      border: none;
      border-radius: 5px;
      color: #fff;
      padding: 7px 20px;
    }
  }

  .attemptCourseDetail3 {
    background-color: #FFFFFF;
    border: 1px solid #edeaea;
    height: 393px;
    width: 675px;
    border-radius: 2px;
    margin-top: 31px;
    // position: relative;
    // top: -175px;

    .flex {
      display: flex;
      font-family: "Roboto", sans-serif;
      padding-bottom: 10px;
    }
  }

  .attemptCourseDetailbook {
    border-bottom: 1px solid #e0e0e0;
    // padding-bottom: 10px;
    margin-top: 20px;
    width: 675px;
    height: 313px;
    background-color: #FFFFFF;
    margin-bottom: 60px;

    .activate {
      width: 605px;
      position: relative;
      top: 45px;
      left: 11px;
      padding-bottom: 20px;
      // border-bottom: 1px solid #E7E7E7;

      .font {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        padding-bottom: 12px;
        font-weight: 400;
        line-height: 20.58px;
        color: #000000;
      }

      .activationProcess {
        color: #3B63E7;
        font-weight: 400;
        font-size: 14px;
        line-height:20.58px;
        cursor: pointer;
      }
    }

    .aboutCourse {
      font-size: 22px;
      font-weight: 500;
      line-height: 32.34px;
      position: relative;
      left: 35px;
      top: 35px;
    }

    .courseP1 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.58px;
      position: relative;
      left: 35px;
      top: 45px;
      color: #000000;
      width: 605px;
      height: 126px;
    }

    .borderBottom{
      border: 1px solid #E7E7E7;
      position: relative;
      top: 270px;
      left: 35px;
      width: 606px;
    }

    .pdfArea{
      background-color: #F6F6F6;
      position: relative;
      top: 310px;
      left: 35px;
      width: 605px;
      height: 91px;

      .pdfImg{
        width: 20.53px;
        height: 23.15px;
        position: relative;
        left: 28px;
        top: 9px 
      }

      .topicWise {
        width: 325px;
        height: 42px;
        font-size: 14px;
        line-height: 20.58px;
        font-weight: 400;
        position: relative;
        top: 15px;
      }

      .greenBack {
        width: 225px;
        height: 45px;
        background-color: #149B43;
        border-radius: 4px;
        position: relative;
        top: 14px;
        left: 10px;
      }

      .donloadpdf{
        color: #FFFFFF;
        position: relative;
        top: 11px;
        left: 35px;
        font-size: 16px;
        line-height: 23.52px;
        font-weight: 500;
      }
    }
  }

  .attemptCourseDetail4 {
    border-bottom: 1px solid #e0e0e0;
    // padding-bottom: 10px;
    margin-top: 20px;
    width: 675px;
    height: 620px;
    background-color: #FFFFFF;
    margin-bottom: 60px;

    .activate {
      width: 605px;
      position: relative;
      top: 45px;
      left: 11px;
      padding-bottom: 20px;
      // border-bottom: 1px solid #E7E7E7;

      .font {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        padding-bottom: 12px;
        font-weight: 400;
        line-height: 20.58px;
        color: #000000;
      }

      .activationProcess {
        color: #3B63E7;
        font-weight: 400;
        font-size: 14px;
        line-height:20.58px;
        cursor: pointer;
      }
    }

    .aboutCourse {
      font-size: 22px;
      font-weight: 500;
      line-height: 32.34px;
      position: relative;
      left: 35px;
      top: 35px;
    }

    .courseP1 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.58px;
      position: relative;
      left: 35px;
      top: 45px;
      color: #000000;
      width: 605px;
      height: 126px;
    }

    .borderBottom{
      border: 1px solid #E7E7E7;
      position: relative;
      top: 270px;
      left: 35px;
      width: 606px;
    }

    .pdfArea{
      background-color: #F6F6F6;
      position: relative;
      top: 310px;
      left: 35px;
      width: 605px;
      height: 91px;

      .pdfImg{
        width: 20.53px;
        height: 23.15px;
        position: relative;
        left: 28px;
        top: 9px 
      }

      .topicWise {
        width: 325px;
        height: 42px;
        font-size: 14px;
        line-height: 20.58px;
        font-weight: 400;
        position: relative;
        top: 15px;
      }

      .greenBack {
        width: 225px;
        height: 45px;
        background-color: #149B43;
        border-radius: 4px;
        position: relative;
        top: 14px;
        left: 10px;
      }

      .donloadpdf{
        color: #FFFFFF;
        position: relative;
        top: 11px;
        left: 35px;
        font-size: 16px;
        line-height: 23.52px;
        font-weight: 500;
      }
    }
  }

  .attemptCourseDetail5 {
    height: 100px;
    width: 96%;

    .systemReq {
      font-family: Raleway, sans-serif;
      font-weight: 700;
      font-size: 20px;
    }

    .activate2 {
      width: 100%;
      padding-left: 0px;

      .font2 {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
      }
    }

    .downloadArea {
      background-color: #f5f6f7;
      border: 2px solid #d9d6d6;
      border-radius: 5px;
      cursor: pointer;
      font-family: Raleway, sans-serif;
      height: 55px;
      width: 285px;

      .pdfImg {
        height: 45px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 3px;
        width: 45px;
      }

      .lectureIndex {
        color: #3a529d;
        font-size: 18px;
        font-weight: 600;
        padding-top: 13px;
        margin-left: -3px;
      }
    }
  }

  .attemptCourseDetail6 {
    width: 96%;
    margin-left: -15px;

    .classroomBenefits {
      border: none;
      border-radius: 5px 5px 0 0;
      padding: 12px 25px;
      background-color: #3a529d;
      color: #fff;
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      font-weight: 600;
    }

    .aboutFac {
      background-color: #e8eeff;
      color: #717171;
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      font-weight: 600;
      border: none;
      border-radius: 5px 5px 0 0;
      padding: 12px 25px;
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .attemptCourseDetail7 {
    width: 96%;
    border: 1px solid #eeeeee;
    font-family: "Roboto", sans-serif;
    height: auto;
    padding: 10px;
    background-color: #fcfdff;

    .systemReq2 {
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-weight: 900;
      padding-left: 40px;
      padding-top: 15px;
    }

    .flexSmart {
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      font-weight: 400;
      display: flex;
      padding-bottom: 12px;
    }

    .aboutFaculty {
      margin-bottom: 20px;
      margin-left: 40px;
    }

    p {
      // margin-top: -20px;
      margin-left: 20px;
    }
  }
}

.section3 {
  .rightSide {
    // background-color: #FFFFFF;
    position: relative;
    top: -117px;
  }
  .overLayWrap {
    background-color: rgba(196, 196, 196, 0.48);
    position: absolute;
    z-index: 99;
    width: 292px;
    height: 166px;
    left: -1px;
    justify-content: center;
    align-items: center;
    top: 40px;
  }
  .overlayStart {
    z-index: 9999;
    color: red;
    font-size: 18px;
    font-weight: bold;
    background: #fff;
    padding: 30px;
    border-top: 1px solid red;
    border-bottom: 1px solid red;
    margin: 192px 0px;
    line-height: 27px;
    text-align: center;
  }
  .blueBtnDetail {
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    // margin-left: 20px;

    width: 140px;
    z-index: 99;
    background-color: #3a529d;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-family: "Roboto", sans-serif;
  }

  .grayBtnDetail {
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    margin-left: 20px;

    width: 120px;
    z-index: 9;
    background-color: #fff;
    border: 2px solid #3a529d;
    color: #000;
  }

  .grayBtnDetail1 {
    background-color: #3a529d;
    color: #fff;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    margin-left: 20px;

    width: 120px;
    z-index: 9;
    border: 1px solid #3a529d;
  }

  .blueBtnDetail1 {
    background-color: #fff;
    border: 2px solid #3a529d;
    border-radius: 5px;
    color: #000;
    font-family: Raleway, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    // margin-left: 20px;
    width: 140px;
    z-index: 99;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .variants {
    position: relative;
    top: 0px;
    /* border: 1px solid #eeeeee; */
    width: 100%;
    height: auto;
    /* border-radius: 5px; */
    padding-left: 25px;
    padding-bottom: 25px;
    background-color: #FFFFFF;
    height: 655px;

    .qnti {
      font-size: 26px;
      font-weight: 500;
      line-height: 38.23px;
      color: #000000;
      position: relative;
      top: 20px;
    }

    .batchDet{
      position: relative;
      top: 25px;
      left: 3px;
      font-size: 18px;
      font-weight: 400; 
    }

    .paramName{
      color: #003399;
      font-size: 18px;
      font-weight: 700;
      line-height: 26.46px;
    }

    .attem{
      color: #3B63E7;
      line-height: 26.46px;
      font-size: 18px;
      font-weight: 400;
    }

    .totlP{
      position: relative;
      top: 40px;
      left: 5px;
      color: #149B43;
      font-size: 30px;
      font-weight: 500;
      line-height: 44.11px;
      font-family: "Roboto", sans-serif;
    }

    .inclusive{
      position: relative;
      top: 32px;
      left: 5px;
      color: #6A6A6A;
      font-size: 16px;
      line-height: 23.52px;
      font-weight: 400;
    }

    .totlPBook{
      position: relative;
      top: 40px;
      left: 2px;
      color: #149B43;
      font-size: 30px;
      font-weight: 500;
      line-height: 44.11px;
      font-family: "Roboto", sans-serif;
    }

    .inclusiveBook{
      position: relative;
      top: 32px;
      left: 2px;
      color: #6A6A6A;
      font-size: 16px;
      line-height: 23.52px;
      font-weight: 400;
    }

    .borderPrices{
      position: relative;
      top: 60px;
      left: 5px;
      border: 1px solid #E7E7E7;
      width: 300px;
    }

    .vidArea {
      //   border-radius: 5px;
      //   height: 185px;
      //   margin-bottom: 5px;
      //   margin-left: 6px;
      //   margin-top: 28px;
      //   width: 90%;
      width: 90%;
      height: 185px;
      margin-top: 28px;
      margin-left: 6px;
      margin-bottom: 5px;
      border-radius: 5px;
    }

    .deletePrice {
      color: red;
      font-size: 16px;
      font-weight: 500;
    }

    .offPrice {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    .rupeeColor {
      font-size: 16px;
      font-weight: 500;
      color: #139413;
    }

    .pricesNote {
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      border-bottom: 1px solid #d9d7d7;
      padding-bottom: 20px;
    }

    .oprSystem {
      // border-bottom: 1px solid #dedcdc;
      height: 65px;
      margin-top: 11px;
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      position: relative;
      top: 65px;

      h4 {
        font-size: 22px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 38px;
        left: 10px;
        border: 1px solid #E7E7E7;
        width: 300px 
      }
    }

    .oprSystem2 {
      // border-bottom: 1px solid #dedcdc;
      height: 65px;
      margin-top: 11px;
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      position: relative;
      top: 85px;

      h4 {
        font-size: 22px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 35px;
        left: 10px;
        border: 1px solid #E7E7E7;
        width: 300px 
      }
    }

    .oprSystem3 {
      height: 65px;
      /* margin-top: 11px; */
      font-family: "Roboto", sans-serif;
      /* margin-left: 6px; */
      width: 90%;
      position: relative;
      top: 105px;
      left: 5px;

      h4 {
        font-size: 22px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 95px;
        right: 15px;
        border: 1px solid #E7E7E7;
        width: 300px;
      }
    }

    .buyBtnArea {
      text-align: center;
      font-family: "Roboto", sans-serif;
      position: relative;
      top: 170px;
      right: 10px;

      .buyCourseBtn {
        background-color: #149B43;
        border: 2px solid;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        height: 49px;
        width: 300px;
      }
    }

    .buyBtnAreaBook {
      text-align: center;
      font-family: "Roboto", sans-serif;
      position: relative;
      top: 90px;
      right: 15px;

      .buyCourseBtn {
        background-color: #003399;
        border: 2px solid;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        height: 49px;
        width: 300px;
      }
    }

    .applyBtnArea {
      position: relative;
      top: 145px;
      left: 10px;

      .applyCoupon {
        border-bottom: 1px solid #3B63E7;
        color: #3B63E7;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 500;
        /* padding-bottom: 7px; */
        cursor: pointer;
        line-height: 29.4px;
      }
    }

    .applyBtnAreaBook {
      position: relative;
      top: 60px;
      left: 3px;

      .applyCoupon {
        border-bottom: 1px solid #3B63E7;
        color: #3B63E7;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 500;
        /* padding-bottom: 7px; */
        cursor: pointer;
        line-height: 29.4px;
      }
    }
  }

  .variantsBook {
    position: relative;
    top: 0px;
    /* border: 1px solid #eeeeee; */
    width: 100%;
    /* border-radius: 5px; */
    padding-left: 25px;
    padding-bottom: 25px;
    background-color: #FFFFFF;
    height: 395px;

    .qnti {
      font-size: 24px;
      font-weight: 500;
      line-height: 28.23px;
      color: #000000;
      position: relative;
      top: 20px;
      font-family: roboto;
    }

    .batchDet{
      position: relative;
      top: 25px;
      left: 3px;
      font-size: 18px;
      font-weight: 400; 
    }

    .paramName{
      color: #003399;
      font-size: 18px;
      font-weight: 700;
      line-height: 26.46px;
    }

    .attem{
      color: #3B63E7;
      line-height: 26.46px;
      font-size: 18px;
      font-weight: 400;
    }

    .totlP{
      position: relative;
      top: 40px;
      left: 5px;
      color: #149B43;
      font-size: 30px;
      font-weight: 500;
      line-height: 44.11px;
      font-family: roboto;
    }

    .inclusive{
      position: relative;
      top: 32px;
      left: 5px;
      color: #6A6A6A;
      font-size: 16px;
      line-height: 23.52px;
      font-weight: 400;
    }

    .totlPBook{
      position: relative;
      top: 40px;
      left: 2px;
      color: #149B43;
      font-size: 30px;
      font-weight: 500;
      line-height: 44.11px;
      font-family: "Roboto", sans-serif;
    }

    .inclusiveBook{
      position: relative;
      top: 32px;
      left: 2px;
      color: #6A6A6A;
      font-size: 16px;
      line-height: 23.52px;
      font-weight: 400;
    }

    .borderPrices{
      position: relative;
      top: 60px;
      left: 5px;
      border: 1px solid #E7E7E7;
      width: 300px;
    }

    .vidArea {
      //   border-radius: 5px;
      //   height: 185px;
      //   margin-bottom: 5px;
      //   margin-left: 6px;
      //   margin-top: 28px;
      //   width: 90%;
      width: 90%;
      height: 185px;
      margin-top: 28px;
      margin-left: 6px;
      margin-bottom: 5px;
      border-radius: 5px;
    }

    .deletePrice {
      color: red;
      font-size: 16px;
      font-weight: 500;
    }

    .offPrice {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    .rupeeColor {
      font-size: 16px;
      font-weight: 500;
      color: #139413;
    }

    .pricesNote {
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      border-bottom: 1px solid #d9d7d7;
      padding-bottom: 20px;
    }

    .oprSystem {
      // border-bottom: 1px solid #dedcdc;
      height: 65px;
      margin-top: 11px;
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      position: relative;
      top: 65px;

      h4 {
        font-size: 22px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 35px;
        left: 10px;
        border: 1px solid #E7E7E7;
        width: 300px 
      }
    }

    .oprSystem2 {
      // border-bottom: 1px solid #dedcdc;
      height: 65px;
      margin-top: 11px;
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      position: relative;
      top: 85px;

      h4 {
        font-size: 22px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 35px;
        left: 10px;
        border: 1px solid #E7E7E7;
        width: 300px 
      }
    }

    .oprSystem3 {
      height: 65px;
      /* margin-top: 11px; */
      font-family: "Roboto", sans-serif;
      /* margin-left: 6px; */
      width: 90%;
      position: relative;
      top: 105px;
      left: 5px;

      h4 {
        font-size: 22px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 90px;
        right: 15px;
        border: 1px solid #E7E7E7;
        width: 300px;
      }
    }

    .buyBtnArea {
      text-align: center;
      font-family: "Roboto", sans-serif;
      position: relative;
      top: 170px;
      right: 10px;

      .buyCourseBtn {
        background-color: #003399;
        border: 2px solid;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        height: 49px;
        width: 300px;
      }
    }

    .buyBtnAreaBook {
      text-align: center;
      font-family: "Roboto", sans-serif;
      position: relative;
      top: 90px;
      right: 15px;

      .buyCourseBtn {
        background-color: #003399;
        border: 2px solid;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        height: 49px;
        width: 300px;
      }
    }

    .applyBtnArea {
      position: relative;
      top: 145px;
      left: 10px;

      .applyCoupon {
        border-bottom: 1px solid #3B63E7;
        color: #3B63E7;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 500;
        /* padding-bottom: 7px; */
        cursor: pointer;
        line-height: 29.4px;
      }
    }

    .applyBtnAreaBook {
      position: relative;
      top: 60px;
      left: 3px;

      .applyCoupon {
        border-bottom: 1px solid #3B63E7;
        color: #3B63E7;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 500;
        /* padding-bottom: 7px; */
        cursor: pointer;
        line-height: 29.4px;
      }
    }
  }
}
.couponWrapperResp {
  position: relative;
  top: 35px;
  left: 0px;
}

.couponWrapperRespBook {
  position: relative;
  // top: 35px;
  left: -3px;
}

.couponWrapper {
  position: relative;
  top: 155px;
  left: 10px;
}

.couponWrapperBook {
  position: relative;
  top: 65px;
  left: 3px;
}

.verifyBtn {
  margin-left: 10px;
  padding: 7px 10px;
  background: #3a529d;
  border: none;
  color: #fff;
  width: 23%;
  border-radius: 5px;
}
.couponSuccessMessageResp {
  color: green;
  text-transform: capitalize;
  /* padding-top: 5px; */
  position: relative;
  left: 0px;
  font-size: 12px;
}
.couponFailMessageResp {
  color: red;
  text-transform: capitalize;
  /* padding-top: 5px; */
  position: relative;
  left: 0px;
  font-size: 12px;
}

.couponSuccessMessage {
  color: green;
  text-transform: capitalize;
  /* padding-top: 5px; */
  position: relative;
  left: 10px;
  font-size: 12px;
}
.couponFailMessage {
  color: red;
  text-transform: capitalize;
  /* padding-top: 5px; */
  position: relative;
  left: 10px;
  font-size: 12px;
}

.systemRe{
  position: relative;
  top: 30px;
  background-color: #FFFFFF;
  height: 170px;
  border-radius: 2px;
  background-color: #FFFFFF;

  .sysText{
    font-size: 18px;
    line-height: 26.46px;
    font-weight: 500;
    /* text-align: center; */
    position: relative;
    left: 35px;
    top: 25px 
  }

  .activate2{
    position: relative;
    top: 27px;
    left: 12px 
  }
}

.regardingFac{
  position: relative;
  top: 60px;
  background-color: #FFFFFF;
  height: 147px;
  border-radius: 2px;

  .facText{
    font-size: 18px;
    line-height: 26.46px;
    font-weight: 500;
    /* text-align: center; */
    position: relative;
    left: 35px;
    top: 15px;
  }
  .facBorder{
    border: 1px solid #E7E7E7;
    width: 295px;
    position: relative;
    top: 10px;
    left: 35px;
  }

  .activate2{
    position: relative;
    top: 27px;
    left: 12px 
  }

  .facDetails{
    position: relative;
    top:27px;
  }

  .nameArea{
    position: relative;
    top: 6px;
    left: 20px;
  }

  .facname{
    font-size: 18px;
    font-weight: 500;
    line-height: 26.46px;
  }

  .facProfession{
    font-size: 14px;
    font-weight: 400;
    line-height: 20.58px;
  }

  .knowMore{
    font-size: 10px;
    font-weight: 400;
    line-height: 14.7px;
    border: 1px solid #898989;
    padding-left: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    position: relative;
    top: 10px;
  }
}

.systemResp{
  position: relative;
  top: 80px;
  background-color: #FFFFFF;
  height: 190px;
  border-radius: 2px;
  background-color: #FFFFFF;

  .sysText{
    font-size: 18px;
    line-height: 26.46px;
    font-weight: 500;
    /* text-align: center; */
    position: relative;
    left: 35px;
    top: 25px 
  }

  .activate2{
    position: relative;
    top: 27px;
    // left: 12px 
    text-align: justify;
  }
}

.regardingFacResp{
  position: relative;
  top: 100px;
  background-color: #FFFFFF;
  height: 147px;
  border-radius: 2px;

  .facText{
    font-size: 18px;
    line-height: 26.46px;
    font-weight: 500;
    /* text-align: center; */
    position: relative;
    left: 35px;
    top: 15px;
  }
  .facBorder{
    border: 1px solid #E7E7E7;
    width: 300px;
    position: relative;
    top: 10px;
    left: 30px;
  }

  .activate2{
    position: relative;
    top: 27px;
    left: 12px 
  }

  .facDetails{
    position: relative;
    top:27px;
  }

  .nameArea{
    position: relative;
    top: 6px;
    left: 20px;
  }

  .facname{
    font-size: 16px;
    font-weight: 500;
    line-height: 23.52px;
  }

  .facProfession{
    font-size: 12px;
    font-weight: 400;
    line-height: 17.64px;
  }

  .knowMore{
    font-size: 8px;
    font-weight: 400;
    line-height: 11.76px;
    border: 1px solid #898989;
    padding-left: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    position: relative;
    top: 10px;
  }
}
.regardingFacRespBook{
  position: relative;
  top: 125px;
  background-color: #FFFFFF;
  height: 147px;
  border-radius: 2px;

  .facText{
    font-size: 16px;
    line-height: 26.46px;
    font-weight: 700;
    /* text-align: center; */
    position: relative;
    left: 20px;
    top: 15px;
  }
  .facBorder{
    border: 1px solid #E7E7E7;
    width: 300px;
    position: relative;
    top: 10px;
    left: 30px;
  }

  .activate2{
    position: relative;
    top: 27px;
    left: 12px 
  }

  .facDetails{
    position: relative;
    top:27px;
  }

  .nameArea{
    position: relative;
    top: 6px;
    left: 20px;
  }

  .facname{
    font-size: 14px;
    font-weight: 500;
    line-height: 23.52px;
  }

  .facProfession{
    font-size: 11px;
    font-weight: 400;
    line-height: 17.64px;
  }

  .knowMore{
    font-size: 8px;
    font-weight: 400;
    line-height: 11.76px;
    border: 1px solid #898989;
    padding-left: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    position: relative;
    top: 10px;
  }
}
@media only screen and (min-width: 360px) and (max-width:768px) {
  .Web {
    display: none;
  }
  .overLayWrap {
    background-color: rgba(196, 196, 196, 0.48);
    position: absolute;
    z-index: 99;
    width: 322px;
    height: 166px;
    left: 22px;
    justify-content: center;
    align-items: center;
  }
  .Responsive {
    display: block;
    background-color: #f8f7f7;
    height: 2030px;
  }

  .ResponsiveBook {
    display: block;
    background-color: #f8f7f7;
    height: 800px;
  }

  .section1 {
    background-image: url("../images/detailsBackResp.png");
    // width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    .fonts1 {
      font-size: 24.05px !important;
      position: relative;
      left: 17px;
      top: 10px;
      font-weight: 700;
      line-height: 35.37px;
      color: #FFFFFF;
    }

    .fonts1Resp {
      font-size: 18.05px !important;
      position: relative;
      left: 17px;
      top: 10px;
      font-weight: 700;
      line-height: 35.37px;
      color: #FFFFFF;
    }

    .titleName1 {
      font-size: 15.12px;
      /* padding-left: 30px; */
      line-height: 23px;
      /* margin-left: -3px; */
      position: relative;
      left: 20px;
      top: -8px;
      font-weight: 500;
      color: #EEEEEE;
      line-height: 22.23px;
    }
    .titleName1Resp {
      font-size: 13.12px;
      /* padding-left: 30px; */
      line-height: 23px;
      /* margin-left: -3px; */
      position: relative;
      left: 20px;
      top: -8px;
      font-weight: 500;
      color: #EEEEEE;
      line-height: 22.23px;
    }
  }

  // .section1CheckOut {
  //   background-image: url("../images/detailsBackResp.png");
  //   // width: 100%;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   height: 90px;

  //   h1 {
  //     font-size: 24.05px !important;
  //     font-weight: 700;
  //     position: relative;
  //     left: -120px;
  //     top: 15px;
  //   }

  //   .titleName {
  //     font-size: 15px;
  //     /* padding-left: 30px; */
  //     line-height: 23px;
  //     /* margin-left: -3px; */
  //     position: relative;
  //     left: -82px;
  //     top: -20px 
  //   }
  // }

  .details {
    text-align: left;
    position: relative;
    left: 0px;
  }

  .courseNameDetail {
    position: relative;
    top: 20px;
    font-weight: 600;
    font-size: 14px;
    left: 16px;
    top: -31px;
    color: #000;
    line-height: 30px;
  }

  .courseNameDetail {
    position: relative;
    top: 20px;
    font-weight: 600;
    font-size: 14px;
    /* text-align: left; */
    left: 16px;
    top: -43px;
    color: #fff;
    line-height: 30px;
  }

  .imgHeight {
    height: 70px;
  }

  .attemptCourse2 {
    text-align: center;
    position: relative;
    top: 0px !important;
    align-content: center;
  }

  .attemptCourseDetail {
    position: relative;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #dedbdb;
  }

  .variants {
    position: relative;
    top: 0px;
    left: 0;
    /* border: 1px solid #eeeeee; */
    width: 100%;
    height: auto;
    /* border-radius: 5px; */
    padding-left: 25px;
    padding-bottom: 25px;
    background-color: #FFFFFF;
    height: 630px;

    .qnti {
      font-size: 20px;
      font-weight: 500;
      line-height: 38.23px;
      color: #000000;
      position: relative;
      top: -15px;
    }

    .batchDet{
      position: relative;
      top: -17px;
      left: 3px;
      font-size: 15px;
      font-weight: 400;
    }

    .batchDetBookResp{
      position: relative;
      top: 15px;
      // left: -6px;
      font-size: 15px;
      font-weight: 400;
    }

    .paramName{
      color: #003399;
      font-size: 15px;
      font-weight: 700;
      line-height: 26.46px;
    }

    .attem{
      color: #3B63E7;
      line-height: 26.46px;
      font-size: 15px;
      font-weight: 400;
    }

    .totlP{
      position: relative;
      top: -4px;
      left: 2px;
      color: #149B43;
      font-size: 26px;
      font-weight: 500;
      line-height: 44.11px;
      font-family: "Roboto", sans-serif;
    }
    .totlPBookResp{
      position: relative;
      top: 15px;
      left: -5px;
      color: #149B43;
      font-size: 26px;
      font-weight: 500;
      line-height: 44.11px;
      font-family: "Roboto", sans-serif;
    }

    .inclusive{
      position: relative;
      top: -12px;
      left: 2px;
      color: #6A6A6A;
      font-size: 14px;
      line-height: 23.52px;
      font-weight: 400;
    }

    .inclusiveBookResp{
      position: relative;
      top: 5px;
      left: -5px;
      color: #6A6A6A;
      font-size: 14px;
      line-height: 23.52px;
      font-weight: 400;
    }

    .borderPrices{
      position: relative;
      top: 12px;
      left: 2px;
      border: 1px solid #E7E7E7;
      width: 300px;
    }

    .vidArea {
      //   border-radius: 5px;
      //   height: 185px;
      //   margin-bottom: 5px;
      //   margin-left: 6px;
      //   margin-top: 28px;
      //   width: 90%;
      width: 90%;
      height: 185px;
      margin-top: 28px;
      margin-left: 6px;
      margin-bottom: 5px;
      border-radius: 5px;
    }

    .deletePrice {
      color: red;
      font-size: 16px;
      font-weight: 500;
    }

    .offPrice {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    .rupeeColor {
      font-size: 16px;
      font-weight: 500;
      color: #139413;
    }

    .pricesNote {
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      border-bottom: 1px solid #d9d7d7;
      padding-bottom: 20px;
    }

    .oprSystem {
      // border-bottom: 1px solid #dedcdc;
      height: 65px;
      margin-top: 11px;
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      position: relative;
      top: 15px;

      h4 {
        font-size: 17px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 13px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 35px;
        left: 10px;
        border: 1px solid #E7E7E7;
        width: 300px 
      }
    }

    .oprSystem2 {
      // border-bottom: 1px solid #dedcdc;
      height: 65px;
      margin-top: 11px;
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      position: relative;
      top: 25px;

      h4 {
        font-size: 17px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 13px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 35px;
        left: 10px;
        border: 1px solid #E7E7E7;
        width: 300px 
      }
    }

    .oprSystem3 {
      height: 65px;
      /* margin-top: 11px; */
      font-family: "Roboto", sans-serif;
      /* margin-left: 6px; */
      width: 90%;
      position: relative;
      top: 37px;
      left: 5px;

      h4 {
        font-size: 17px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 13px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 90px;
        right: 15px;
        border: 1px solid #E7E7E7;
        width: 300px;
      }
    }
    .oprSystem4 {
      height: 65px;
      /* margin-top: 11px; */
      font-family: "Roboto", sans-serif;
      /* margin-left: 6px; */
      width: 90%;
      position: relative;
      top: 15px;
      left: 5px;

      h4 {
        font-size: 17px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 13px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 90px;
        right: 15px;
        border: 1px solid #E7E7E7;
        width: 300px;
      }
    }
    .buyBtnArea {
      text-align: center;
      font-family: "Roboto", sans-serif;
      position: relative;
      top: 170px;
      right: 10px;

      .buyCourseBtn {
        background-color: #149B43;
        border: 2px solid;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        height: 49px;
        width: 300px;
      }
    }

    .applyBtnArea {
      position: relative;
      top: 60px;
      right: 10px;

      .applyCoupon {
        border-bottom: 1px solid #3B63E7;
        color: #3B63E7;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 500;
        /* padding-bottom: 7px; */
        cursor: pointer;
        line-height: 29.4px;
      }
    }
    .applyBtnAreaBookResp {
      position: relative;
      top: 5px;
      left: 1px;

      .applyCoupon {
        border-bottom: 1px solid #3B63E7;
        color: #3B63E7;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 500;
        /* padding-bottom: 7px; */
        cursor: pointer;
        line-height: 29.4px;
      }
    }
  }

  .variantsBookResp {
    position: relative;
    top: 45px;
    left: 0;
    /* border: 1px solid #eeeeee; */
    width: 100%;
    height: auto;
    /* border-radius: 5px; */
    padding-left: 25px;
    padding-bottom: 25px;
    background-color: #FFFFFF;
    height: 205px;

    .qnti {
      font-size: 20px;
      font-weight: 500;
      line-height: 38.23px;
      color: #000000;
      position: relative;
      top: -15px;
      left: -8px;
    }

    .qntiResp {
      font-size: 18x !important;
      font-weight: 600;
      line-height: 25.23px;
      color: #000000;
      position: relative;
      // top: -15px;
      // left: -8px;
    }

    .batchDet{
      position: relative;
      top: -17px;
      left: 3px;
      font-size: 15px;
      font-weight: 400;
    }

    .batchDetBookResp{
      position: relative;
      top: -17px;
      left: -6px;
      font-size: 13px;
      font-weight: 400;
    }

    .paramName{
      color: #003399;
      font-size: 15px;
      font-weight: 700;
      line-height: 26.46px;
    }

    .attem{
      color: #3B63E7;
      line-height: 26.46px;
      font-size: 15px;
      font-weight: 400;
    }

    .totlP{
      position: relative;
      top: -4px;
      left: 2px;
      color: #149B43;
      font-size: 26px;
      font-weight: 500;
      line-height: 30px;
      font-family: "Roboto", sans-serif;
    }
    .totlPBookResp{
      position: relative;
      top: -4px;
      // left: -5px;
      color: #149B43;
      font-size: 18px;
      font-weight: 900;
      line-height: 45px;
      // font-family: "Roboto", sans-serif;
      // letter-spacing: 1px;
    }

    .inclusive{
      position: relative;
      top: -12px;
      left: 2px;
      color: #6A6A6A;
      font-size: 14px;
      line-height: 23.52px;
      font-weight: 400;
    }

    .inclusiveBookResp{
      position: relative;
      top: -12px;
      left: -5px;
      color: #6A6A6A;
      font-size: 12px;
      line-height: 23.52px;
      font-weight: 400;
    }

    .borderPrices{
      position: relative;
      top: 12px;
      left: 2px;
      border: 1px solid #E7E7E7;
      width: 300px;
    }

    .vidArea {
      //   border-radius: 5px;
      //   height: 185px;
      //   margin-bottom: 5px;
      //   margin-left: 6px;
      //   margin-top: 28px;
      //   width: 90%;
      width: 90%;
      height: 185px;
      margin-top: 28px;
      margin-left: 6px;
      margin-bottom: 5px;
      border-radius: 5px;
    }

    .deletePrice {
      color: red;
      font-size: 16px;
      font-weight: 500;
    }

    .offPrice {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    .rupeeColor {
      font-size: 16px;
      font-weight: 500;
      color: #139413;
    }

    .pricesNote {
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      border-bottom: 1px solid #d9d7d7;
      padding-bottom: 20px;
    }

    .oprSystem {
      // border-bottom: 1px solid #dedcdc;
      height: 65px;
      margin-top: 11px;
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      position: relative;
      top: 15px;

      h4 {
        font-size: 17px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 13px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 35px;
        left: 10px;
        border: 1px solid #E7E7E7;
        width: 300px 
      }
    }

    .oprSystem2 {
      // border-bottom: 1px solid #dedcdc;
      height: 65px;
      margin-top: 11px;
      font-family: "Roboto", sans-serif;
      margin-left: 6px;
      width: 90%;
      position: relative;
      top: 35px;

      h4 {
        font-size: 17px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 13px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 35px;
        left: 10px;
        border: 1px solid #E7E7E7;
        width: 300px 
      }
    }

    .oprSystem3 {
      height: 65px;
      /* margin-top: 11px; */
      font-family: "Roboto", sans-serif;
      /* margin-left: 6px; */
      width: 90%;
      position: relative;
      top: 55px;
      left: 5px;

      h4 {
        font-size: 17px;
        font-weight: 500;
        line-height: 32.34px;
      }

      .varName {
        font-size: 13px;
        font-weight: 400;
        margin-right: 20px;
        line-height: 20.58px;
      }

      .varAlign{
        position: relative;
        left: -27px;
      }

      .oprBorder{
        position: relative;
        top: 90px;
        right: 15px;
        border: 1px solid #E7E7E7;
        width: 300px;
      }
    }

    .buyBtnArea {
      text-align: center;
      font-family: "Roboto", sans-serif;
      position: relative;
      top: 170px;
      right: 10px;

      .buyCourseBtn {
        background-color: #003399;
        border: 2px solid;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        height: 49px;
        width: 300px;
      }
    }

    .applyBtnArea {
      position: relative;
      top: 60px;
      left: 10px;

      .applyCoupon {
        border-bottom: 1px solid #3B63E7;
        color: #3B63E7;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 500;
        /* padding-bottom: 7px; */
        cursor: pointer;
        line-height: 29.4px;
      }
    }
    .applyBtnAreaBookResp {
      position: relative;
      top: 5px;
      left: 1px;

      .applyCoupon {
        border-bottom: 1px solid #3B63E7;
        color: #3B63E7;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 500;
        /* padding-bottom: 7px; */
        cursor: pointer;
        line-height: 29.4px;
      }
    }
  }
}
