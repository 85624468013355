.subjectfooter {
  position: relative;
  top: 320px;
  width: 100%;
  height: 610px;
  background-color: #f0f3f4;
}
.footerLogo {
  width: 310px;
  height: 100px;
  position: relative;
  left: 80px;
}
.details {
  text-align: left;
  position: relative;
  left: 80px;
}
.details1396 {
  text-align: left;
  position: relative;
  left: 80px;
}
.forDetail {
  font-size: 17px;
  color: #747171;
  line-height: 22px;
}
.instructor {
  position: relative;
  top: 45px;
  left: 0px;
  text-align: left;
  color: #747171;
  h4 {
    font-size: 20px;
    padding-bottom: 10px;
  }
}
.explore {
  position: relative;
  top: 45px;
  left: 0px;
  text-align: left;
  color: #747171;
  h4 {
    font-size: 20px;
    padding-bottom: 10px;
  }
}
.inputMail {
  position: relative;
  right: 22px;
  width: 60%;
  height: 61px;
  border: none;
  top: 48px;
  background-color: #fbfaf1;
  padding-left: 38px;
}
.emailHex {
  position: relative;
  top: 51px;
  z-index: 99;
  font-size: 22px;
  left: 15px;
}
.sendEmail {
  position: absolute;
  top: 105px;
  right: 142px;
  background-color: #ffc107;
  width: 60px;
  height: 61px;
}
.sendImg {
  width: 40px;
  height: 40px;
  position: relative;
  top: 12px;
  left: 10px;
  cursor: pointer;
}
.downloadApp {
  position: relative;
  top: 80px;
  text-align: left;
  left: 0px;
}
.footerLast {
  position: absolute;
  background-color: #020239;
  top: 460px;
  width: 82%;
  height: 150px;
  left: 9%;
  border-radius: 10px 10px 0px 0px;
}
.akName {
  color: #d6cfcf;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 30px;
}
.akNamePara {
  color: #d6cfcf;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 30px;
  bottom: 9px;
}
.headphone {
  width: 75px;
  height: 75px;
  position: relative;
  right: 0px;
}
.haveQuestion {
  color: #d6cfcf;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 20px;
  bottom: 15px;
  font-weight: 500;
}
.mobileNumber {
  position: relative;
  left: 75px;
  bottom: 25px;
}
.footerMarg {
  position: relative;
  top: 60px;
}
.footerWebTop{
    top: 140px;
}
@media only screen and (max-width: 360px) {
  .footerRespTop{
    top: 140px;

  }
  .subjectfooter {
    position: relative;
    top: 139px;
    width: 100%;
    height: 610px;
    background-color: #f0f3f4;
  }
  .footerMarg {
    position: relative;
    top: 30px;
  }
}
