.testimonials {
  width: 100%;
  // height: 500px;
  padding-bottom: 5rem;

  @media screen and (max-width: 768px) {
    padding-bottom: 0rem;
  }
}
.whatSay {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 37px;
  text-transform: capitalize;
}
.boxArea {
  border: 1px solid gray;
  width: 90%;
  height: 270px;
  position: relative;
  left: 70px;
  border-radius: 6px;
}
.boxArea2 {
  width: 95%;
  height: 248px;
  position: relative;
  border-radius: 6px;
  margin: 10px;
  background-color: #ded6d6;
}
.marks {
  // background-color: #077c07;
  background-color: #0668e2;
  color: #fff;
  width: 75%;
  height: 42px;
  border: none;
  position: relative;
  top: 20px;
  left: 39px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
}
.bottomTesti {
  position: relative;
  left: 70px;
  width: 90%;
  height: 90px;
  border-bottom: 1px solid #c3baba;
}
.studentName {
  font-size: 16px;
  text-align: left;
  color: #1313a1;
  font-family: "Nunito", sans-serif;
}
.batchName {
  font-size: 16px;
  text-align: left;
  font-family: "Nunito", sans-serif;
}
@media only screen and (max-width: 360px) {
  .testimonials {
    width: 100%;
    height: auto;
  }
  .boxArea2 {
    width: 95%;
    height: 128px;
    position: relative;
    border-radius: 6px;
    margin: 10px;
    background-color: #ded6d6;
  }
  .whatSay {
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
  .whatFont {
    font-size: 18px;
  }
  // .studentRating1 {
  //   margin-left: -63px;
  //   padding: 0px;
  // }
  // .studentRating2 {
  //   display: none;
  // }
  // .studentRating3 {
  //   display: none;
  // }
  .boxArea {
    border: 1px solid gray;
    width: 80%;
    height: 147px;
    position: relative;
    left: 21px;
    border-radius: 6px;
  }
  .marks {
    // background-color: #077c07;
    background-color: #1313a1;
    color: #fff;
    width: 140%;
    height: 42px;
    border: none;
    position: relative;
    top: 20px;
    left: 7px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  .studentName {
    font-size: 14px;
    text-align: left;
    color: #1313a1;
    font-family: "Nunito", sans-serif;
  }
  .batchName {
    font-size: 14px;
    text-align: left;
    font-family: "Nunito", sans-serif;
  }
  .bottomTesti {
    position: relative;
    left: 21px;
    width: 80%;
    height: 90px;
    border-bottom: 1px solid #c3baba;
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (max-width: 1000px) {
  .testimonials {
    width: 100%;
    height: auto;
  }
  .boxArea2 {
    width: 95%;
    height: 120px;
    position: relative;
    border-radius: 6px;
    margin: 10px;
    background-color: #ded6d6;
  }
  .whatSay {
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
  .whatFont {
    font-size: 18px;
  }
  .studentRating1 {
    width: 100%;
    margin-left: 15px;
    padding: 0px;
  }
  .studentRating2 {
    width: 100%;
    margin-left: 65px;
    padding: 0px;
  }
  .studentRating3 {
    width: 100%;
    margin-left: 105px;
    padding: 0px;
  }
  .boxArea {
    border: 1px solid gray;
    width: 80%;
    height: 147px;
    position: relative;
    left: 21px;
    border-radius: 6px;
  }
  .marks {
    // background-color: #077c07;
    background-color: #0668e2;
    color: #fff;
    width: 140%;
    height: 42px;
    border: none;
    position: relative;
    top: 20px;
    left: 7px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  .studentName {
    font-size: 14px;
    text-align: left;
    color: #1313a1;
    font-family: "Nunito", sans-serif;
  }
  .batchName {
    font-size: 14px;
    text-align: left;
    font-family: "Nunito", sans-serif;
  }
  .bottomTesti {
    position: relative;
    left: 21px;
    width: 80%;
    height: 90px;
    border-bottom: 1px solid #c3baba;
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width:820px) and (max-width: 1180px) {
  .testimonials {
    width: 100%;
    height: auto;
  }
  .boxArea2 {
    width: 95%;
    height: 128px;
    position: relative;
    border-radius: 6px;
    margin: 10px;
    background-color: #ded6d6;
  }
  .whatSay {
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
  .whatFont {
    font-size: 18px;
  }
  // .studentRating1 {
  //   margin-left: -63px;
  //   padding: 0px;
  // }
  // .studentRating2 {
  //   display: none;
  // }
  // .studentRating3 {
  //   display: none;
  // }
  .boxArea {
    border: 1px solid gray;
    width: 80%;
    height: 147px;
    position: relative;
    left: 21px;
    border-radius: 6px;
  }
  .marks {
    // background-color: #077c07;
    background-color: #1313a1;
    color: #fff;
    width: 140%;
    height: 42px;
    border: none;
    position: relative;
    top: 20px;
    left: 7px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
  }
  .studentName {
    font-size: 14px;
    text-align: left;
    color: #1313a1;
    font-family: "Nunito", sans-serif;
  }
  .batchName {
    font-size: 14px;
    text-align: left;
    font-family: "Nunito", sans-serif;
  }
  .bottomTesti {
    position: relative;
    left: 21px;
    width: 80%;
    height: 90px;
    border-bottom: 1px solid #c3baba;
    display: flex;
    flex-direction: row;
  }
}

// new Testimonials styles
.testimonials-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 10rem;

  .swiper
    .swiper-initialized
    .swiper-horizontal
    .swiper-pointer-events
    .swiper-backface-hidden {
    padding: 0rem 0rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0rem 2rem;
  }

  .testimonial-card {
    .testimonial-divider {
      width: 100%;
      height: 0.5px;
      background-color: #bbbbbb;
      margin-top: 2rem;
    }

    .testimonial-video {
      border: 1px solid #000000;
      padding: 2rem;
      border-radius: 0.5rem;
    }

    .testimonial-review-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;

      .review-info {
        h4 {
          margin: 0;
          color: #3a519d;
        }

        span {
          display: inline-block;
          color: #000000;
          font-weight: 500;
          margin-top: 0.5rem;
        }
      }

      .review-marks {
        background-color: #3b63e7;
        padding: 1rem 2rem;
        border-radius: 0.5rem;

        h5 {
          margin: 0;
          color: white;
        }
      }
    }
  }
}
