.AboutUs{
    h1{
        
        text-align: center;
        margin-top: 40px;
        text-decoration: underline;
        margin-bottom: 40px;
        
    }
    p
    {
        padding: 5px 10px;
    }
   

}
@media screen and (max-width: 768px) {
    .AboutUs{
        h1{
        margin-top: 30px;
        margin-bottom: 10px;

            
        }
        img{
            width: 100%;
        }
     }
}