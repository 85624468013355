.showAling {
  position: relative;
  top: 13px;
}

.wingName1 {
  font-weight: 700;
  font-size: 20px;
  position: relative;
  right: 45px;
}
.hrMargin {
  margin-left: 16px;
}

.wingName1536 {
  font-weight: 700;
  font-size: 20px;
  // position: relative;
  // left: 95px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  text-align: left;
}

.wingName2 {
  font-weight: 700;
  font-size: 20px;
  // position: relative;
  // left: 15px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  text-align: left;
}

.imgWrapper {
  background-image: url("https://admin.akclasses.in//storage/Courses/images/1721953439.jpg");
  // background-color: #87d757;
  border-radius: 5px;
  height: 348px;
  width: 100%;
  margin-left: 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.param {
  color: #3f92ad;
}

.param2 {
  color: green;
}

.imageAreaMain {
  border-radius: 5px;
  border: 1px solid #d6d2d2;
  width: 100%;
}

.imageAreaMainRight {
  width: 78%;
  height: 660px;
  border-radius: 5px;
  position: absolute;
  left: 30px;
  border: 1px solid #d6d2d2;
}

.imageArea {
  width: 100%;
  height: 310px;
  background-color: #87d757;
  // background: rgb(255,255,255);
  // background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(67,156,14,0.29485297536983546) 20%, rgba(135,215,87,1) 95%);
  // background: rgb(255,255,255);
  // background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(135,215,87,1) 55%);
  border-radius: 5px;
  position: absolute;
  // left: 190px;
}

.imageAreaRight {
  width: 100%;
  height: 310px;
  background-color: #9aeeee;
  border-radius: 5px;
  position: absolute;
  // left: 190px;
}

.fastTrack1 {
  color: blue;
  border: none;
  background-color: #fff;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  margin-top: 40px;
  font-size: 18.44px;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
}

.fastTrack2 {
  color: blue;
  border: none;
  background-color: #fff;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  position: relative;
  top: 20px;
  left: 10px;
  font-size: 18.44px;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
}

.subject {
  margin-top: 20px;
  font-weight: bold;
  font-size: 24.87px;
  text-align: left;
  font-family: "Raleway", sans-serif;
}

.courseName {
  font-weight: bold;
  font-size: 24.87px;
  text-align: left;
  color: #fff;
  line-height: 30px;
  font-family: "Raleway", sans-serif;
}

.faculty {
  font-weight: bold;
  font-size: 24.87px;
  text-align: left;
  color: #458a45;
  font-family: "Raleway", sans-serif;
}

.facultyRight {
  position: relative;
  top: 20px;
  font-weight: bold;
  font-size: 24.87px;
  text-align: left;
  left: 10px;
  color: #6cbdbd;
  font-family: "Raleway", sans-serif;
}

.facultyImg {
  // position: absolute;
  // top: 8px;
  width: 81%;
  margin-top: 8px;
}

.facultyImg1396 {
  position: relative;
  top: 8px;
  width: 198px;
}

.facultyImgRight {
  position: relative;
  top: 6px;
  left: 30px;
  width: 85%;
}

.facultyImgRight1396 {
  position: relative;
  top: 8px;
  left: 5px;
  width: 95%;
}

.registerEnd {
  color: #d30909;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}

.days {
  text-align: left;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
}

.totalDays {
  font-weight: 700;
}

.daysDiv {
  position: relative;
  left: 135px;
  top: 10px;
}

.daysArea {
  width: 100%;
  height: 270px;
  // background-color: #87d757;
  // position: absolute;
  // top: 47%;
  border-bottom: 1px solid #d6d2d2;

  h3 {
    font-size: 18px;
    font-weight: bolder;
  }
}

.ttl {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-family: 18px;
}

.hexCode {
  font-size: 17px;
  font-weight: 700;
  color: #077c07;
  padding-right: 10px;
}

.hexCodeRight {
  font-size: 20px;
  font-weight: 700;
  color: #6cb6b6;
}

.enrollArea {
  width: 100%;
  height: 65px;
}

.enrollBtn {
  background-color: #6dc23a;
  border: none;
  border-radius: 5px;
  width: 130px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
  font-family: "Nunito", sans-serif;
}

.enrollBtnRight {
  background-color: #6cb6b6;
  border: none;
  border-radius: 5px;
  width: 130px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  position: relative;
  top: 9px;
  font-family: "Nunito", sans-serif;
}

@media only screen and (min-width: 1200px) {
  .facultyImg {
    width: 91%;
    height: 30.4rem;
    margin-top: 8px;
  }

  .facultyImgRight {
    position: relative;
    top: 6px;
    left: 30px;
    width: 85%;
    height: 30.8rem;
  }
}
@media only screen and (width: 360px) {
  .imgWrapper {
    height: 206px !important;
  }
}
@media only screen and (min-width: 360px) and (max-width: 768px) {
  // .imgWrapper {
  //   background-color: #87d757;
  //   border-radius: 5px;
  //   height: 206px;
  //   width: 100%;
  //   margin-left: 0px;
  // }

  // .studentRating1 {
  //   width: 100%;
  // }
  // .studentRating2 {
  //   width: 100%;
  // }
  // .studentRating3 {
  //   width: 100%;
  // }

  .testimonials {
    display: block;
  }

  // .boxArea2 {
  //   height: 50px !important;
  // }

  .imgWrapper {
    border-radius: 5px;
    height: 228px;
    // height: 406px;
    width: 100%;
    margin-left: 0px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .paddingClass {
    padding-left: 10px;
  }

  .fastTrack1 {
    color: blue;
    border: none;
    background-color: #fff;
    width: 137px;
    height: 40px;
    border-radius: 5px;
    margin-top: 40px;
    font-size: 12.44px;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
  }

  .imageAreaMainRight {
    width: 91%;
    height: 580px;
    border-radius: 5px;
    position: absolute;
    // left: 30px;
    border: 1px solid #d6d2d2;
  }

  .param {
    color: #3f92ad;
  }

  .wingName2 {
    font-weight: 400;
    font-size: 15px;
    position: relative;
    left: 0px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
  }

  .imageAreaRight {
    width: 300px;
    height: 260px;
    background-color: #9aeeee;
    border-radius: 5px;
    position: absolute;
    // left: 190px;
  }

  .fastTrack2 {
    color: blue;
    border: none;
    background-color: #fff;
    width: 105px;
    height: 40px;
    border-radius: 5px;
    position: relative;
    top: 20px;
    left: 10px;
    font-size: 12px;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
  }

  .subject {
    font-weight: bold;
    font-size: 15px;
    text-align: left;
    left: 10px;
    font-family: "Raleway", sans-serif;
    color: #fff;
  }

  .courseName {
    font-weight: bold;
    font-size: 23px;
    text-align: left;
    color: #fff;
    line-height: 21px;
    font-family: "Raleway", sans-serif;
    margin-top: 15px;
  }

  .faculty {
    font-weight: bold;
    font-size: 19.87px;
    text-align: left;
    color: #458a45;
    font-family: "Raleway", sans-serif;
  }

  .facultyImg {
    width: 100%;
    margin-top: 57px;
  }

  .showAling {
    position: relative;
    top: 13px;
    width: 170px;
  }

  .facultyRight {
    position: relative;
    top: 20px;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    left: 10px;
    color: #fff;
    font-family: "Raleway", sans-serif;
  }

  .facultyImgRight {
    left: 152px;
    width: 50%;
    top: -201px;
    height: 250px;
  }

  .daysDiv {
    position: relative;
    left: 10px;
    top: 10px;
  }

  .ttl {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-family: 10px;
  }

  .hexCodeRight {
    font-size: 16px;
    font-weight: 700;
    color: #6cb6b6;
  }

  .enrollArea {
    width: 100%;
    height: 65px;
  }

  .enrollBtn {
    background-color: #6dc23a;
    border: none;
    border-radius: 5px;
    width: 125px;
    height: 33px;
    color: #fff;
    font-size: 15px;
    margin-top: 15px;
    font-family: "Nunito", sans-serif;
    margin-left: -10px;
  }

  .priceMarg {
    font-size: 17px;
    margin-right: 10px;
  }

  .enrollBtnRight {
    background-color: #6cb6b6;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 28px;
    color: #fff;
    font-size: 16px;
    position: relative;
    // top: 9px;
    margin-top: 22px;
    font-family: "Nunito", sans-serif;
  }

  .wingName1536 {
    font-weight: 700;
    font-size: 16px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    text-align: left;
    display: block;
  }

  .hideSecondDiv {
    display: none;
  }

  .prizeEnroll {
    margin-top: 12px;
  }

  .fontSize {
    font-size: 14px;
  }

  .hrMargin {
    margin-left: 10px;
  }

  .displayEnroll {
    display: flex;
    flex-direction: row;
    width: 98%;

    .hrEnroll {
      font-size: 15px;
      width: 65px;
      margin-top: 40px;
    }

    .enrollAdd {
      padding: 0px;
    }
  }
}
@media screen and (min-width:820px) and (max-width: 1180px) {
  // .imgWrapper {
  //   background-color: #87d757;
  //   border-radius: 5px;
  //   height: 206px;
  //   width: 100%;
  //   margin-left: 0px;
  // }
  .imgWrapper {
    border-radius: 5px;
    height: 228px;
    // height: 406px;
    width: 100%;
    margin-left: 0px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .paddingClass {
    padding-left: 10px;
  }

  .fastTrack1 {
    color: blue;
    border: none;
    background-color: #fff;
    width: 137px;
    height: 40px;
    border-radius: 5px;
    margin-top: 40px;
    font-size: 12.44px;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
  }

  .imageAreaMainRight {
    width: 91%;
    height: 580px;
    border-radius: 5px;
    position: absolute;
    // left: 30px;
    border: 1px solid #d6d2d2;
  }

  .param {
    color: #3f92ad;
  }

  .wingName2 {
    font-weight: 400;
    font-size: 15px;
    position: relative;
    left: 0px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
  }

  .imageAreaRight {
    width: 300px;
    height: 260px;
    background-color: #9aeeee;
    border-radius: 5px;
    position: absolute;
    // left: 190px;
  }

  .fastTrack2 {
    color: blue;
    border: none;
    background-color: #fff;
    width: 105px;
    height: 40px;
    border-radius: 5px;
    position: relative;
    top: 20px;
    left: 10px;
    font-size: 12px;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
  }

  .subject {
    font-weight: bold;
    font-size: 15px;
    text-align: left;
    left: 10px;
    font-family: "Raleway", sans-serif;
    color: #fff;
  }

  .courseName {
    font-weight: bold;
    font-size: 23px;
    text-align: left;
    color: #fff;
    line-height: 21px;
    font-family: "Raleway", sans-serif;
    margin-top: 15px;
  }

  .faculty {
    font-weight: bold;
    font-size: 19.87px;
    text-align: left;
    color: #458a45;
    font-family: "Raleway", sans-serif;
  }

  .facultyImg {
    width: 100%;
    margin-top: 57px;
  }

  .showAling {
    position: relative;
    top: 13px;
    width: 170px;
  }

  .facultyRight {
    position: relative;
    top: 20px;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    left: 10px;
    color: #fff;
    font-family: "Raleway", sans-serif;
  }

  .facultyImgRight {
    left: 152px;
    width: 50%;
    top: -201px;
    height: 250px;
  }

  .daysDiv {
    position: relative;
    left: 10px;
    top: 10px;
  }

  .ttl {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-family: 10px;
  }

  .hexCodeRight {
    font-size: 16px;
    font-weight: 700;
    color: #6cb6b6;
  }

  .enrollArea {
    width: 100%;
    height: 65px;
  }

  .enrollBtn {
    background-color: #6dc23a;
    border: none;
    border-radius: 5px;
    width: 125px;
    height: 33px;
    color: #fff;
    font-size: 15px;
    margin-top: 15px;
    font-family: "Nunito", sans-serif;
    margin-left: -10px;
  }

  .priceMarg {
    font-size: 17px;
    margin-right: 10px;
  }

  .enrollBtnRight {
    background-color: #6cb6b6;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 28px;
    color: #fff;
    font-size: 16px;
    position: relative;
    // top: 9px;
    margin-top: 22px;
    font-family: "Nunito", sans-serif;
  }

  .wingName1536 {
    font-weight: 700;
    font-size: 16px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    text-align: left;
    display: block;
  }

  .hideSecondDiv {
    display: none;
  }

  .prizeEnroll {
    margin-top: 12px;
  }

  .fontSize {
    font-size: 14px;
  }

  .hrMargin {
    margin-left: 10px;
  }

  .displayEnroll {
    display: flex;
    flex-direction: row;
    width: 98%;

    .hrEnroll {
      font-size: 15px;
      width: 65px;
      margin-top: 40px;
    }

    .enrollAdd {
      padding: 0px;
    }
  }
  .contain {
    margin-left: auto !important;
  }
  .container {
    margin-left: auto !important;
  }
}

.contain {
  margin-left: auto !important;
}

.batches-container {
  // display: flex;
  // justify-content: center;
  // align-items: flex-start;
  // column-gap: 3rem;
  // row-gap: 3rem;
  text-align: center;

  padding: 0rem 10rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0rem 4rem;
  }

  // .swiper-pagination {
  //   width: 100%;
  //   top: 100%;
  // }

  .batch-container {
    strong {
      display: inline-block;
      color: #c71212;
      font-size: 1.6rem;
    }

    .batch-card {
      margin-top: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.42);
      border-radius: 0.5rem;
      overflow: hidden;

      .batch-thumb {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 16 / 9;
          // border-radius: 1rem;

          @media screen and (max-width: 768px) {
            height: 100%;
          }
        }
      }

      .batch-info {
        padding: 2rem 0rem;
        h5 {
          margin: 0;
          color: #1b1b1b;
          font-size: 1.8rem;
          font-weight: bold;
          padding: 0rem 2rem;

          @media screen and (max-width: 768px) {
            font-size: 1.5rem;
          }
        }

        .langauge-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 1rem 2rem 0rem 2rem;

          .lang-date {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            background-color: #003399;
            color: white;
          }

          h6 {
            margin: 0;
            font-size: 1.5rem;
            color: #7a7a7a;

            span {
              color: #3a519d;
              display: inline-block;
            }
          }
        }
      }

      .batch-divider-line {
        width: 100%;
        height: 1px;
        background-color: #cecece;
        position: relative;
        top: -0.8rem;
      }

      .batch-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem 1.2rem 2rem;

        @media screen and (max-width: 768px) {
          padding: 0rem 2rem 1.2rem 2rem;
        }

        h6 {
          margin: 0;
          color: #0c7429;
          font-size: 1.8rem;
          font-weight: bold;

          @media screen and (max-width: 768px) {
            font-size: 1.5rem;

            &:nth-of-type(2) {
              position: relative;
              right: 0.7rem;
            }
          }
        }

        button {
          border: none;
          border-radius: 0.5rem;
          padding: 1rem 2rem;
          // margin-right: 2rem;
          background-color: #2f970b;
          color: white;
          font-family: inherit;
          font-weight: bold;

          @media screen and (max-width: 768px) {
            margin-right: 0.5rem;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
