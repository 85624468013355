.faCheck {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  padding: 4px;
  padding-top: 10px;
  font-size: 16px;
}
.posterImage {
  width: 250px;
  height: 165px;
}
.faDivRadio {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  padding: 4px;
  padding-top: 10px;
  font-size: 16px;
}

.faSpot {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  padding: 4px;
  font-size: 14px;
  color: #516ade;
}

.FaDays {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  padding: 4px;
  font-size: 16px;
  color: #ca2d2a;
}

.BatchStarts {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background-color: #4f4e4e;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  width: 90%;
  padding: 8px;
  font-size: 17px;
}

.attemptCourse {
  text-align: center;
  position: relative;
  top: 40px;
}

.attemptCourse2 {
  text-align: center;
  position: relative;
  top: 70px;
  align-content: center;
  // display: flex;
  // justify-content: space-evenly;
  // align-items: center;
}

.showing {
  font-size: 23px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
}

.selectAtt {
  width: 10%;
  height: 38px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
}

.jun {
  color: #139413;
}

.blueBtn {
  border: none;
  width: 100px;
  height: 32px;
  background-color: #0668e2;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  z-index: 99;
  position: relative;
}

.grayBtn {
  border: none;
  width: 120px;
  height: 32px;
  background-color: rgb(213, 213, 218);
  color: #000;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  position: relative;
  right: 25px;
  z-index: 9;
}

.leftCourseLand {
  // background-color: antiquewhite;
  width: 86%;
  position: absolute;
  left: 7%;
  text-align: left;
}

.lectureNote {
  background-color: #0668e2;
  width: 100%;
  height: 70px;
  padding: 8px;
  position: relative;
  top: 12px;
  color: #fff;
  border-radius: 5px;
}

.lectureNote1 {
  background-color: #3a519d;
  width: 100%;
  height: 70px;
  padding: 8px;
  color: #fff;
  margin-top: 30px;
  border-radius: 5px;
}

.lectures {
  font-size: 15px;
  padding-left: 7px;
}

.batchType {
  // position: relative;
  // top: 38px;
  border: 1px solid #c4bfbf;
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding-left: 25px;
  padding-bottom: 25px;
}

.typeName {
  font-weight: 600;
}

.checkDiv {
  font-size: 16px;
  padding-top: 10px;
}

.chooseSubjects {
  // position: relative;
  // top: 65px;
  border: 1px solid #e3e3e3;
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding-left: 25px;
  padding-bottom: 25px;
}

.courseDivArea {
  border: 1px solid #e3e3e3;
  // height: 460px !important;
  // border-radius: 5px;
}

.batchbtnclass {
  position: relative;
  left: 76px;
}

// .batchButton {
//     border: none;
//     background-color:#f25b00;
//     color: #fff;
//     height: 40px;
//     width: 140px;
//     border-radius: 5px 5px 0px 0px;
//     position: relative;
//     top: 11px;
//     right: 33px;
//     font-size: 15px;
// }
.FabatchButton {
  border: none;
  background-color: #f58b40;
  color: #fff;
  height: 40px;
  width: 140px;
  border-radius: 5px 5px 0px 0px;
  position: relative;
  top: 4px;
  right: 33px;
  font-size: 18px;
  font-weight: 600;
}

.registration1 {
  font-family: "Nunito", sans-serif;
  text-align: center;
  color: #ea3737;
  margin-bottom: 6px;
  margin-left: -20px !important;
  margin-top: 11px;
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 1px;
}

// .batchBackColor {
//     background-color:#ec9659;
//     height: 165px;
//     border-radius: 5px;
//     position: relative;
//     // top: -2px;
// }
.fabatchBackColor {
  background-color: #f3f3f3;
  height: 165px;
  border-radius: 5px;
  position: relative;
  // top: -2px;
}

.faBackcolor {
  background-color: #da7e3de9;
  height: 165px;
  border-radius: 5px;
  z-index: 999;
}

.akPic1 {
  margin-left: -15px;
  margin-top: -1px;
}

.courseRightArea {
  position: relative;
  top: 25px;
  left: 10px;
}

.subjTitle {
  color: #206e20;
  font-size: 16px;
}

.coursenametitle {
  color: #fff;
}

.daysAreaCourses {
  width: 100%;
  // height: 225px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #e3e3e3;

  // h5 {
  //   color: #4f4e4e;
  //   font-size: 17px;
  //   padding: 0px 43px;
  //   font-weight: bold;
  // }
  @media screen and (min-width: 998px) {
    margin-top: 17rem;
  }
}

.daysDivCourse {
  margin-left: 20px;
}

.daysCourse {
  text-align: left;
  font-size: 13px;
}

.enrollAreaCourse {
  width: 100%;
  margin-top: -18px;
  // position: absolute;
  // top: 389px;
  // left: 0px;

  @media screen and (max-width: 768px) {
    margin-top: 47px;
  }
}

.hexTime {
  color: rgb(7, 124, 7);
  // position: absolute;
  // right: 22px;
  // top: 10px;
  font-size: 14px;
  // left: 0px;
}

.faHexTime {
  color: rgb(7, 124, 7);
  position: absolute;
  font-size: 15px;
  left: 0px;
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  padding: 4px;
  font-size: 20px;
  font-weight: 900;
  left: -8px;
  top: 1px;
}

// .enrollBtnCourse {
//     background-color: #6dc23a;
//     border: none;
//     border-radius: 5px;
//     width: 110px;
//     height: 33px;
//     color: #fff;
//     font-size: 14px;
//     position: relative;
//     top: 12px;
//     right: 23px
// }
.faEnrollBtnCourse {
  background-color: #f25b00;
  border: none;
  border-radius: 5px;
  width: 105px;
  height: 33px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  top: 10px;
  right: -14px;
}

// right: 23px

.courseAmt {
  color: rgb(7, 124, 7);
  position: absolute;
  top: -5px;
  right: 2px;
  font-size: 20px;
}

.batchSystem {
  border: 1px solid #c5bebe;
  width: 932px;
  height: auto;
  padding: 15px;
}

.questions {
  font-weight: 900 !important;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
}

.answers {
  font-family: "Nunito", sans-serif;
  font-weight: 400 !important;
}

.arrowHexRight {
  text-align: right;
  position: relative;
  top: 0px;
  cursor: pointer;
}

.arrowHexBottom {
  text-align: right;
  position: relative;
  top: 5px;
  cursor: pointer;
}

.caTitleLanding {
  color: #fff;
  position: absolute;
  top: 140px;
  font-family: "Nunito", sans-serif;
  font-size: 32px;
  font-weight: 600;
  left: 50px;
}

.section2 {
  // padding-top: 40px !important;
}

.faSubTitle {
  color: #fff;
  position: absolute;
  // top: 113px;
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  font-weight: 400;
  padding-top: 4px;
}

.FaceTitleLanding {
  color: #fff;
  position: absolute;
  // top: 113px;
  left: 50px;
}

.margSec4 {
  margin-right: 21px;
  margin-left: 15px;
  @media screen and (min-width: 360px) {
    margin-left: 0;
    margin-right: 0;
  }
}
.testSeries {
  background-image: url("../images/sub_back.png") !important;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70px;

  h1 {
    color: #fff;
    font-size: 30px;
    font-weight: 900;
    margin-left: 48px;
    // margin-top: -10px;
    padding-top: 21px;
  }

  h5 {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-left: 48px;
  }
}

.anim {
  background: rgb(67, 67, 67);
}
@media only screen and (min-width: 360px) and (max-width:768px) {
  .selectAtt {
    width: 44%;
    height: 38px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
    // color: ;
  }

  .attemptCourse {
    text-align: center;
    position: relative;
    top: 0px;
    margin-top: 30px;
  }

  .showing {
    font-size: 18px !important;
    font-weight: 600;
    text-align: center;
    // margin-right: 0px;
    // padding-left: 25px;
    // padding-right: 25px;
  }

  .caTitleLanding {
    top: 94px !important;
    font-size: 19px !important;
    left: -7px !important;
  }
  .posterImage {
    width: 120%;
    height: 164px;
    margin-left: -30px;
  }
  .daysAreaCourse {
    .subnaame {
      font-size: 16px !important;
      font-weight: bold !important;
      padding: 0 55px !important;
    }
  }

  .subjectFilterBtn {
    background: #0668e2 !important;
    color: #fff;
    border: none;
    // width: 43%;
    height: 39px;
    // border-radius: 8px;
    margin-left: 30px;
    font-weight: 500;
    font-size: 1.5rem;
    padding-left: 10px;
  }

  .lectureNote1 {
    background-color: #3a519d;
    width: 105%;
    height: 70px;
    padding: 8px;
    position: relative;
    top: 23px;
    color: #fff;
    border-radius: 5px;
    left: -18px;
  }

  .regiBatches {
    padding-right: 0px;
    padding-left: 18px;
    padding-right: 45px;
    margin-top: 84px;
  }

  .registration {
    text-align: center;
    color: #ea3737;
    position: relative;
    top: 3px;
    right: 20px;
    width: 100%;
  }

  .FabatchButton {
    border: none;
    background-color: #f58b40;
    color: #fff;
    height: 40px;
    width: 140px;
    border-radius: 5px 5px 0px 0px;
    position: relative;
    top: 1px;
    right: 20px;
    font-size: 15px;
  }

  .batchSystem {
    border: 1px solid #e0e0e0 !important;
    width: 90%;
    height: auto;
    padding: 8px !important;
    margin-left: 4px;
  }

  .leftCourseLand {
    left: 13px !important;
  }

  .faEnrollBtnCourse {
    background-color: #f25b00;
    border: none;
    border-radius: 5px;
    width: 105px;
    height: 33px;
    color: #fff;
    font-size: 14px;
    position: relative;
    top: 12px;
    right: -50px;
  }
}

@media only screen and (min-width: 820px) and (max-width:1180px) {
  .selectAtt {
    width: 44%;
    height: 38px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
    // color: ;
  }

  .attemptCourse {
    text-align: center;
    position: relative;
    top: 0px;
    margin-top: 30px;
  }

  .showing {
    font-size: 18px !important;
    font-weight: 600;
    text-align: center;
    margin-right: 0px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .caTitleLanding {
    top: 94px !important;
    font-size: 19px !important;
    left: -7px !important;
  }
  .posterImage {
    width: 123%;
    height: 164px;
    margin-left: -30px;
  }
  .daysAreaCourse {
    .subnaame {
      font-size: 16px !important;
      font-weight: bold !important;
      padding: 0 55px !important;
    }
  }

  .subjectFilterBtn {
    background: #3a519d;
    color: #fff;
    border: none;
    width: 43%;
    height: 39px;
    border-radius: 8px;
    margin-left: 6px;
    font-weight: 500;
    padding-left: 10px;
  }

  .lectureNote1 {
    background-color: #3a519d;
    width: 95%;
    height: 70px;
    padding: 8px;
    position: relative;
    top: -11px;
    color: #fff;
    border-radius: 5px;
    left: 5px;
  }

  .regiBatches {
    padding-right: 0px;
    padding-left: 18px;
    padding-right: 45px;
    margin-top: 84px;
  }

  .registration {
    text-align: center;
    color: #ea3737;
    position: relative;
    top: 3px;
    right: 16px;
    width: 100%;
  }

  .FabatchButton {
    border: none;
    background-color: #f58b40;
    color: #fff;
    height: 40px;
    width: 140px;
    border-radius: 5px 5px 0px 0px;
    position: relative;
    top: 1px;
    right: 31px;
    font-size: 15px;
  }

  .batchSystem {
    border: 1px solid #e0e0e0 !important;
    width: 90% !important;
    height: auto;
    padding: 8px !important;
    margin-left: 4px;
  }

  .leftCourseLand {
    left: 13px !important;
  }

  .faEnrollBtnCourse {
    background-color: #f25b00;
    border: none;
    border-radius: 5px;
    width: 105px;
    height: 33px;
    color: #fff;
    font-size: 14px;
    position: relative;
    top: 12px;
    right: -34px;
  }
}
@media screen and (width: 360px) {
  .FabatchButton {
    right: 19px;
  }
  .faEnrollBtnCourse {
    right: -25px;
  }
}
@media screen and (width: 768px) {
  .lectureNote1 {
    width: 100%;
  }
  .faEnrollBtnCourse {
    right: -20px;
  }
  .FabatchButton {
    right: 42px;
  }
  .posterImage {
    width: 126%;
  }
}
