.footer {
  position: relative;
  top: 1230px;
  width: 100%;
  height: 610px;
  background-color: #f0f3f4;
}
.iconFlexDiv {
  display: flex;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}
.footerLogo {
  width: 310px;
  height: 100px;
  position: relative;
  left: 80px;
}
.details {
  text-align: left;
  position: relative;
  left: 80px;
}
.details1396 {
  text-align: left;
  position: relative;
  left: 80px;
}
.forDetail {
  font-size: 17px;
  // color: #747171;
  color: white;
  line-height: 22px;
  margin-top: 31px;
}
.fbLogo {
  width: 6%;
  margin: 0px 7px;
}
.instructor {
  position: relative;
  top: 45px;
  left: 50px;
  text-align: left;
  // color: #747171;
  color: white;
  h4 {
    font-size: 16px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    left: 0;
  }
}
.explore {
  position: relative;
  top: 45px;
  left: 0px;
  text-align: left;
  // color: #747171;
  color: white;
  h4 {
    font-size: 16px;
    padding-bottom: 10px;
  }
}
.inputMail {
  position: absolute;
  right: 125px;
  width: 60%;
  height: 61px;
  border: none;
  top: 105px;
  background-color: #fbfaf1;
  padding-left: 38px;
}
.emailHex {
  position: relative;
  top: 65px;
  z-index: 99;
  font-size: 22px;
  left: 70px;
}
.sendEmail {
  position: absolute;
  top: 105px;
  right: 126px;
  background-color: #ffc107;
  width: 60px;
  height: 61px;
}
.sendImg {
  width: 30px;
  height: 30px;
  position: relative;
  top: 18px;
  left: 14px;
  cursor: pointer;
}
.downloadApp {
  position: relative;
  top: 80px;
  text-align: left;
  left: 0px;
  h2 {
    font-size: 30px;
  }
}
.footerLast {
  position: absolute;
  background-color: #020239;
  top: 460px;
  width: 82%;
  height: 150px;
  left: 9%;
  border-radius: 10px 10px 0px 0px;
}
.akName {
  // color: #d6cfcf;
  color: #000000;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 30px;
}
.akNamePara {
  // color: #d6cfcf;
  color: #000000;

  text-align: left;
  font-size: 13px;
  line-height: 20px;
  position: relative;
  left: 30px;
  bottom: 9px;
}
.headphone {
  width: 75px;
  height: 75px;
  position: relative;
  right: 0px;
}
.haveQuestion {
  // color: #d6cfcf;
  color: #000000 !important;

  text-align: left;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 20px;
  bottom: 15px;
  font-weight: 500;
}
.mobileNumber {
  position: relative;
  left: 75px;
  bottom: 25px;
  color: #000000;
}
