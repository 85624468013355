.mainHeadDisplay {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.underLine {
  margin-top: 0px;
  width: 35px;
  margin-left: 1px;
  height: 2px;
  background: #000;
  margin-bottom: 45px;
}
.headerBack {
  background-color: #fff;
  height: 100px;
  // width
  // position: relative;
  // z-index: 1;
}
.scholarImage {
  width: 14%;
  position: absolute;
  right: 8%;
  top: 93px;
}
.username {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 8px;
  top: 18px;
}
.inputTag {
  outline: none;
  border-radius: 0px;
  padding: 18px 18px 18px 30px;
  font-size: 13px !important;
}
// .Menudropdown {
//   position: relative;
//   display: inline;
//   &:hover .Menudropdown-contents {
//     display: block;
//     transform: scaleY(1);
//     transition: 0.5s;
//   }
// }
// .Menudropdown-contents {
//   position: absolute;
//   right: 2%;
//   left: 0%;
//   top: 64px;
//   box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
//   border-top-left-radius: 0px;
//   border-top-right-radius: 0px;
//   border-bottom-left-radius: 10px;
//   border-bottom-right-radius: 10px;
//   transform: scaleY(1);
//   background-color: #fff;
//   color: #000;
//   padding: 16px 20px;
//   transform-origin: top;
//   z-index: 99999999;
//   transition: 0.5s;
// }
// .Menudropdown :hover .Menudropdown-contents {
//   display: block;
//   transform: scaleY(1);
//   transition: 0.5s;
// }

.login {
  font-family: "Nunito", sans-serif;
  margin: 0px 10px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 20px;
}

.register {
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
  border: none;
  margin: 0px 10px;
  border-radius: 5px;
  background-color: #3a519d;
  color: #fff;
  width: 200px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 13px 10px !important;
  margin-top: 16px;
}

.sideBar {
  background-color: #fff;
  width: 31%;
  height: 100%;
  position: fixed;
  right: 0px;
  z-index: 9999999;
  border-radius: 10px 0px 0px 10px;
}

.overlay {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0px;
  z-index: 9;
  border-radius: 10px 0px 0px 10px;
  opacity: 0.5;
  overflow-y: hidden;
}

.removeIcon {
  width: 15px;
  height: 15px;
  margin-top: 19px;

  margin-left: 25px;
  cursor: pointer;
  z-index: 99999;
}

.sectionReg {
  position: absolute;
  top: 70px;
  // left: 40px;
  left: 35px;
}

.signUp {
  color: #000;
  font-weight: 700;
  font-family: "Nunito";
  font-size: 30px;
  // line-height: 56.25px;
  text-align: left;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 2;
  opacity: 0.7;
}

.name {
  width: 352px;
  border: 1px solid #eeecec;
  font-size: 20px;
}

.bottomSpace {
  margin-bottom: 20px;
  position: relative;
}
.notifWrap {
  background: red;
  padding: 7px 10px;
  border-radius: 5px;
  text-align: center;
  width: 85%;
  margin-left: 52px;
  margin-top: -10px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
}
.notifWrapReg {
  background: red;
  padding: 7px 10px;
  border-radius: 5px;
  text-align: center;
  width: 77%;
  margin-left: 39px;
  margin-top: 18px;
  height: 35px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
}

.bottomSpaceBtn {
  margin-bottom: 20px;
  position: relative;
}

.closeEyeIcon {
  width: 20px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.eyeIcon {
  width: 20px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.signinBtn {
  width: 100%;
  height: 40px;
  font-size: 1px;
  font-weight: 600;
  background-color: #3a519d;
  font-family: "Roboto";
  font-size: 17px;
  padding: 23px;
  border-radius: 0px;
}

.alreadyUser {
  cursor: pointer;
  text-align: right;
  color: "#2367EB";
}

//Login
.sectionLog {
  position: absolute;
  top: 70px;
  // left: 40px;
  left: 35px;
}

.bottomSpaceBtnLogin {
  position: relative;
}

.bottomSpace2 {
  margin-bottom: 17px;
  position: relative;
}

.eyeIconLogin {
  width: 20px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

//Header left
// .logo {
//   width: 290px;
//   height: 80px;
//   position: relative;
//   //   right: 30px;
//   bottom: 10px;
// }
.mainDiv {
  position: absolute;
  top: 55px;
}

// .types {
//   font-family: "Nunito", sans-serif;
//   position: relative;
//   top: 0px;
//   font-weight: 900;
//   font-size: 15px;
//   cursor: pointer;
//   border: none;
//   padding-bottom: 7px;
//   background-color: #fff;
//   border: 1px solid #e0e0e0;
//   margin: 6px;
//   border-radius: 5px;
// }
.selectedTypes {
  font-family: "Nunito", sans-serif;
  position: relative;
  top: 0px;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
  background-color: #003399;
  border: none;
  padding-bottom: 7px;
  border: 1px solid #e0e0e0;
  color: #fff;
  border-radius: 5px;
  margin: 6px;
  // margin-top: 15px;
}

.franchisee1536 {
  position: relative;
  top: 7px;
  right: 120px;
}

.franchisee19 {
  position: relative;
  top: 10px;
  right: 50px;
}

.arrowHexHeader {
  position: relative;
  top: 10px;
  font-size: 20px;
  left: 3px;
}

.dropdownall {
  width: 200px;
  height: auto;
  // background-color: #f0f1f6;
  position: absolute;
  top: 43px;
  //   left: 286px;
  z-index: 99;
  text-align: left;
}

.dropdownall2 {
  width: 200px;
  height: auto;
  // background-color: #f0f1f6;
  position: absolute;
  top: 43px;
  //   left: 286px;
  z-index: 99;
  text-align: left;
}

.dropdownall1396 {
  width: 200px;
  height: auto;
  // background-color: #f0f1f6;
  position: absolute;
  top: 43px;
  //   left: 263px;
  z-index: 99;
  text-align: left;
}

.categoryArea {
  border-right: 1px solid #d8cfcf;
  height: auto;
  // margin-right: 10px;
  // width: 31%;
}

.catSelected1396 {
  width: 200px;
  height: 50px;
  background-color: #3a519d;
  position: relative;
  right: 30px;
  color: #fff;
  border: 1px solid #b0abab;
}

.catSelected {
  width: 200px;
  height: 50px;
  background-color: #3a519d;
  position: relative;
  // right: 30px;
  color: #fff;
  border: 1px solid #b0abab;
}

.colorDiv {
  background-color: #fff !important;
  background: #fff !important;
  color: #000;
  cursor: pointer;

  h5 {
    font-size: 15px;
  }

  h5:hover {
    font-size: 15px;
    font-weight: 900;
  }
}

.colorDiv:hover {
  // background-color: rgb(20, 20, 20) !important;
  color: #3a519d !important;
  font-size: 16px !important;
  cursor: pointer;
}

.cat1396 {
  width: 150px;
  height: 50px;
  background-color: #fff;
  position: relative;
  // right: 30px;
  color: #000;
  border: 1px solid #b0abab;
  border-width: 80%;
}

.cat {
  width: 150px;
  height: 50px;
  background-color: #fff;
  position: relative;
  // right: -5px;
  color: #000;
  border: 1px solid #b0abab;
  // border-bottom-width: 40px;

  // border-width: 150px;
  h5 {
    font-size: 13px;
  }

  h5:hover {
    font-size: 13px;
    font-weight: 900;
  }
}

.cat:hover {
  width: 150px;
  height: 50px;
  background-color: rgb(195, 201, 211);
  position: relative;
  // right: 5px;
  color: #3a519d;
  border: 1px solid #b0abab;
  font-weight: 900;
  font-size: 15px;
  // border-width: 20px;
}

.arrowHexCode {
  position: relative;
  top: 2px;
  left: 90px;
  font-size: 25px;
}

.catName {
  position: relative;
  top: 10px;
  // left: 12px;
  width: 98%;
  height: 30px;
  border-bottom: 1px solid #d6cfcf;
  border-width: 80%;
}

.streamsArea {
  position: relative;
  top: 10px;
  left: 12px;
  width: 100%;
  height: auto;
  padding-bottom: 60px;
}

.streamsArea1396 {
  position: relative;
  top: 10px;
  left: 12px;
  width: 100%;
  height: auto;
  padding-bottom: 60px;
}

.streams {
  position: relative;
  right: 28px;
  top: 59px;
}

.contact {
  display: none;
}

.contact1 {
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 8px;
  font-family: "Nunito", sans-serif;
  background-color: #3a519d;
  color: #fff;
  font-size: 18px;
  //   margin-bottom: 20px;
}

@media screen and (min-width: 1700px) {
  .logo {
    width: 252px;
    height: 78px;
    position: relative;
    right: 30px;
    bottom: 10px;
  }

  .selectedTypes {
    font-family: "Nunito", sans-serif;
    position: relative;
    top: 0px;
    font-weight: 900;
    font-size: 12px;
    cursor: pointer;
    background-color: #003399;
    border: none;
    padding-bottom: 7px;
    color: #fff;
    border-radius: 5px;
  }

  .types {
    font-family: "Nunito", sans-serif;
    position: relative;
    top: 0px;
    font-weight: 900;
    font-size: 12px;
    cursor: pointer;
    border: none;
    padding-bottom: 7px;
    background-color: #fff;
    left: 10px;
  }

  .catSelected {
    width: 200px;
    height: 50px;
    background-color: #3a519d;
    position: relative;
    right: 30px;
    color: #fff;
    border: 1px solid #b0abab;
  }

  .cat {
    width: 200px;
    height: 50px;
    background-color: #f0f1f6;
    position: relative;
    right: 30px;
    color: #000;
    border: 1px solid #b0abab;
  }

  .streamsArea {
    position: relative;
    top: 10px;
    left: 12px;
    width: 100%;
    height: auto;
    padding-bottom: 60px;
    font-size: 14px !important;
  }
}

.selectTag {
  width: 100%;
  border: 1px solid #0000003b;
  padding: 10px;
  border-radius: 4px;
  outline: none;
}
.Web {
  display: block;
}

.Responsive {
  display: none;
}
// NewHeader
.NewHeader {
  width: 100%;
  background-color: #fff;
  .mainWrap {
    display: flex;
    justify-content: space-between;
    height: 58px;

    p {
      padding: 5px 10px;
      align-items: center;
    }
  }
  .headerTopHead {
    background-color: #003399;
    color: #fff;

    font-family: "Roboto", sans-serif;
    font-size: 18px;
    justify-content: center;
    padding: 4px 8px;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .logoAK {
    width: 100%;
    height: 80px;
  }

  .secondWrap {
    display: flex;
  }

  .selectedTypes {
    background-color: #003399;
    font-family: "Nunito", sans-serif;
    font-weight: 900;
    font-size: 15px;
    cursor: pointer;
    border: none;
    border: 1px solid #e0e0e0;
    margin: 8px 10px;
    border-radius: 5px;
    height: 48px;
    margin-top: 16px;
  }

  .types {
    font-family: "Nunito", sans-serif;
    font-weight: 900;
    font-size: 15px;
    cursor: pointer;
    border: none;
    background-color: #fff;
    border: 1px solid #003399;
    margin: 8px 10px;
    border-radius: 5px;
    height: 48px;
    margin-top: 16px;
  }

  .dropdownall {
    width: 200px;
    height: auto;
    // background-color: #f0f1f6;
    position: absolute;
    top: 100px;
    z-index: 99;
    left: 21.3%;
    text-align: left;
  }

  .dropdownall2 {
    width: 200px;
    height: auto;
    // background-color: #f0f1f6;
    position: absolute;
    top: 100px;
    z-index: 99;
    left: 22%;
    text-align: left;
  }
  

  ._show {
    transform: translateX(0);
    transition: 0.5s;
  }

  ._hide {
    transform: translateX(665px);
    transition: 0.5s;
  }
  ._showNotif {
    transform: translateY(-14px);
    transition: 0.5s;
  }
}
// responsive
// @media only screen and (max-width: 360px) {
//   .Web {
//     display: none;
//   }

//   .Responsive {
//     display: block;
//   }
//   .notifWrap{
//     background:red;
//     padding: 7px 10px;
//     border-radius: 5px;
//     text-align: center;
//     width: 97%;
//       margin-left: 7px;
//       margin-top: 30px;
//       box-shadow: 0 20px 25px -5px rgba(0, 0, 0 , 0.1), 0 8px 10px -6px rgba(0 ,0, 0 , 0.1);

//   }
//   .notifWrapReg {
//     background: red;
//     padding: 7px 10px;
//     border-radius: 5px;
//     text-align: center;
//     width: 97%;
//     margin-left: 7px;
//     margin-top: 30px;
//     box-shadow: 0 20px 25px -5px rgba(0, 0, 0 , 0.1), 0 8px 10px -6px rgba(0 ,0, 0 , 0.1);
//   }
//   .respInput {
//     width: 87%
//   }
//   .sectionReg {
//     justify-content: center;
//     align-items: center;
//     padding: 20px;
//     position: relative;
//     top: 0px;
//     left: 0px;
// }
//   .sideBar {
//     background-color: #fff;
//     width: 100%;
//     height: 99%;
//     position: fixed;
//     z-index: 9999999;
//     border-radius: 10px 10px 10px 10px;
// }
// .bottomSpaceBtn {
//   margin-bottom: 15px;
// }
//   .sectionLog {

//     justify-content: center;
//     align-items: center;
//     padding: 20px;
//     position: relative;
//     top: 0px;
//     left: 0px;
// }
// .signUp {
//   color: #3a519d;
//   font-weight: 700;
//   font-family: "Roboto";
//   font-size: 30px;
//   line-height: 56.25px;
//   text-align: left;
// }
// .removeIcon {
//   position: relative;
//     width: 35px;
//     height: 35px;
//     right: 0px;
//     top: 0px;
//     cursor: pointer;
//     margin-left: 320px;
//     margin-top: 10px;

// }
// .name {
//   width: 315px;
//   border: 1px solid #eeecec;
// }
//   .NewHeader .logoAK {
//     margin-bottom: 10px;
//     z-index: 999;
//     margin-top: 1em;
//     margin-left: -31px;
//     width: 100%;
//     height: 65px !important;
//   }

//   .NewHeader .headerTopHead {
//     background-color: #3a519d;
//     color: #fff;
//     font-family: "Roboto", sans-serif;
//     font-size: 18px;
//     justify-content: center;
//     padding: 2px 8px;
//     text-align: center;
//     height: 40px;
//   }

//   .contact1 {
//     display: none;
//   }

//   .contact {
//     display: block;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     text-align: center;
//     padding: 8px;
//     font-family: "Nunito", sans-serif;
//     background-color: #3a519d;
//     color: #fff;
//     font-size: 14px;
//   }

//   .logo {
//     width: 200px;
//     height: 60px;
//     margin-bottom: 10px;
//     z-index: 999;
//     margin-top: 2em;
//     margin-left: -31px;
//   }

//   .headerBack {
//     display: none;
//   }

//   .navHeader {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     height: 65px;
//   }

//   .menu {
//     border: 1px solid #878787;
//     border-radius: 5px;
//     height: 40px;
//     width: 100px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     justify-content: space-around;
//     font-family: "Nunito", sans-serif;
//     font-weight: 900;

//     .menuImg {
//       margin-left: 18px;
//     }
//   }

//   .RespHeader {
//     background-color: #ddd;
//     width: 100%;
//     position: fixed;
//     top: 0;
//     height: 100vh;
//     z-index: 9999999999;
//     overflow-y: hidden;
//   }

//   .cancelIcon {
//     width: 13px;
//     height: 13px;
//     position: absolute;
//     right: 10px;
//     top: 10px;
//   }

//   .nextIcon {
//     width: 13px;
//     height: 13px;
//     position: absolute;
//     right: 10px;
//   }

//   .secondDivNavbar {
//     p {
//       border-bottom: 1px solid rgb(192, 188, 188);
//       padding: 5px 10px;
//     }
//   }

//   .backBtn {
//     border-bottom: 0.5px solid #3a519d;
//     padding: 5px 10px;
//   }

//   .slideFromRight {
//     animation: slideFromRight 0.2s linear;
//     transition: 1s linear ease-in;
//   }

//   @keyframes slideFromRight {
//     0% {
//       transform: translateX(100px);
//       opacity: 0;
//     }

//     100% {
//       transform: translateX(0);
//       opacity: 1;
//     }
//   }
// }

// Sidebar AK info
.ak-info {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-bottom: 1px solid #eaeaea;
}
.ak-info .info {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.ak-info .info img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.ak-info .info span {
  display: inline-block;
  color: #646464;
  font-size: 1.5rem;
}

.ak-login-container {
  position: absolute;
  bottom: 8rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.ak-login-container .ak-social-icons {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.ak-login-container .ak-social-icons img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.ak-login-container .ak-login-divider {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
  top: 4rem;
}
.ak-login-container .ak-login-btn {
  border-top: 1px solid #eaeaea;
}
.ak-login-container .ak-login-btn button {
  border: none;
  padding: 1rem 5rem;
  border-radius: 0.5rem;
  background-color: #3964e6;
  font-family: inherit;
  color: white;
  font-weight: bold;
}

@media screen and (min-width: 360px) and (max-width: 768px) {
  // @media screen and (min-width: 360px&&  max-width: 767px) {
  .Web {
    display: none !important;
  }
  .NewHeader ._hide {
    transform: translateX(773px);
    transition: 0.5s;
  }
  .Responsive {
    display: block !important;
  }
  .notifWrap {
    background: red;
    padding: 7px 10px;
    border-radius: 5px;
    text-align: center;
    width: 97%;
    margin-left: 7px;
    margin-top: 30px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }
  .notifWrapReg {
    background: red;
    padding: 7px 10px;
    border-radius: 5px;
    text-align: center;
    width: 97%;
    margin-left: 7px;
    margin-top: 30px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }
  .respInput {
    width: 87%;
  }
  .sectionReg {
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
    top: 0px;
    left: 0px;
  }
  .sideBar {
    background-color: #fff;
    width: 100%;
    height: 99%;
    position: fixed;
    z-index: 9999999;
    border-radius: 10px 10px 10px 10px;
  }
  .bottomSpaceBtn {
    margin-bottom: 15px;
  }
  .sectionLog {
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
    top: 0%;
    left: 0%;
  }
  .signUp {
    color: #000;
    font-weight: 700;
    font-family: "Nunito";
    font-size: 30px;
    line-height: 56.25px;
    text-align: left;
  }
  .removeIcon {
    width: 15px;
    height: 15px;
    margin-top: 19px;
    margin-left: 25px;
    cursor: pointer;
    z-index: 99999;
  }
  .name {
    width: 100%;

    border: 1px solid #eeecec;
  }

  // .NewHeader .logoAK {
  //   margin-bottom: -2px;
  //   z-index: 999;
  //   margin-top: 1em;
  //   margin-left: 0.8rem;
  //   // width: 90%;
  //   height: 58px !important;
  //   // left: -72px;
  //   // position: relative;
  // }

  .NewHeader .logoAK {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    margin-bottom: -1.8rem;
    margin-left: 1rem;
  }

  .NewHeader .headerTopHead {
    // background-color: #3a519d;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    justify-content: center;
    padding: 2px 8px;
    text-align: center;
    height: 40px;
  }

  .contact1 {
    display: none;
  }

  .contact {
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding: 8px;
    font-family: "Nunito", sans-serif;
    background-color: #3a519d;
    color: #fff;
    font-size: 14px;
  }

  .logo {
    width: 200px;
    height: 60px;
    margin-bottom: 10px;
    z-index: 999;
    margin-top: 2em;
    margin-left: -31px;
  }

  .headerBack {
    display: none;
  }

  .navHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 45px;
  }

  .menu {
    border: 1px solid #878787;
    border-radius: 5px;
    height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    font-family: "Nunito", sans-serif;
    font-weight: 900;
    margin-top: 13px;
    position: absolute;
    right: 10px;

    .menuImg {
      margin-left: 18px;
    }
  }

  .RespHeader {
    background-color: white;
    width: 100%;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 9999999999;
    overflow-y: hidden;

    .resp-logo-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      .ak-logo {
        display: flex;
        align-items: center;
        gap: 1rem;
        img {
          width: 5rem;
          height: 5rem;
          object-fit: cover;
        }

        .logo-title {
          h1 {
            margin: 0;
            font-size: 1.5rem;
            font-weight: bolder;
            color: #003399;
          }

          h5 {
            margin: 0;
            font-size: 1rem;
            font-family: Caveat, cursive;
            text-align: right;
          }
        }
      }

      .cancel-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 2rem;
        height: 2rem;
        border: 2px solid #3b63e7;
        border-radius: 50%;
        padding: 1.2rem;
        color: #3b63e7;
        font-size: 1.5rem;
      }
    }
  }

  .cancelIcon {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .nextIcon {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 10px;
  }

  .secondDivNavbar {
    p {
      border-bottom: 1px solid #eaeaea;
      padding: 5px 10px;
      color: #646464;
    }
  }

  .backBtn {
    border-bottom: 0.5px solid #3a519d;
    padding: 5px 10px;
  }

  .slideFromRight {
    animation: slideFromRight 0.8s linear;
    transition: 0.5s linear ease-in;
  }

  .slideTop {
    animation: slideTop 0.8s linear;
    transition: 1s linear ease-out;
  }

  @keyframes slideFromRight {
    0% {
      transform: translateX(-200%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideTop {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-200%);
    }
  }
  .closeEyeIcon {
    right: 20px;
  }
  .selectTag {
    width: 100%;
  }
}
@media screen and (min-width:820px) and (max-width: 1180px) {
  .Web {
    display: none !important;
  }
  .NewHeader ._hide {
    transform: translateX(822px);
    transition: 0.5s;
  }
  .Responsive {
    display: block !important;
  }
  .notifWrap {
    background: red;
    padding: 7px 10px;
    border-radius: 5px;
    text-align: center;
    width: 97%;
    margin-left: 7px;
    margin-top: 30px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }
  .notifWrapReg {
    background: red;
    padding: 7px 10px;
    border-radius: 5px;
    text-align: center;
    width: 97%;
    margin-left: 7px;
    margin-top: 30px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }
  .respInput {
    width: 87%;
  }
  .sectionReg {
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .sideBar {
    background-color: #fff;
    width: 100%;
    height: 99%;
    position: fixed;
    z-index: 9999999;
    border-radius: 10px 10px 10px 10px;
  }
  .bottomSpaceBtn {
    margin-bottom: 15px;
  }
  .sectionLog {
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .signUp {
    color: #000;
    font-weight: 700;
    font-family: "Nunito";
    font-size: 30px;
    text-align: left;
  }
  .removeIcon {
    width: 15px;
    height: 15px;
    margin-top: 19px;
    margin-left: 25px;
    cursor: pointer;
    z-index: 99999;
  }
  .scholarImage {
    width: 10%;
    position: absolute;
    right: 30%;
    top: 120px;
  }
  .name {
    width: 320px;
    border: 1px solid #eeecec;
  }

  .NewHeader .logoAK {
    margin-bottom: -2px;
    z-index: 999;
    margin-top: 1em;
    // margin-left: -31px;
    width: 100%;
    height: 58px !important;
    // left: -72px;
    // position: relative;
  }

  .NewHeader .headerTopHead {
    background-color: #003399;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    justify-content: center;
    padding: 2px 8px;
    text-align: center;
    height: 40px;
  }

  .contact1 {
    display: none;
  }

  .contact {
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding: 8px;
    font-family: "Nunito", sans-serif;
    background-color: #3a519d;
    color: #fff;
    font-size: 14px;
  }

  .logo {
    width: 200px;
    height: 60px;
    margin-bottom: 10px;
    z-index: 999;
    margin-top: 2em;
    margin-left: -31px;
  }

  .headerBack {
    display: none;
  }

  .navHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 45px;
  }

  .menu {
    border: 1px solid #878787;
    border-radius: 5px;
    height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    font-family: "Nunito", sans-serif;
    font-weight: 900;
    margin-top: 13px;
    position: absolute;
    right: 10px;

    .menuImg {
      margin-left: 18px;
    }
  }

  .RespHeader {
    background-color: #ddd;
    width: 100%;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 9999999999;
    overflow-y: hidden;
  }

  .cancelIcon {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .nextIcon {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 10px;
  }

  .secondDivNavbar {
    p {
      border-bottom: 1px solid rgb(192, 188, 188);
      padding: 5px 10px;
    }
  }

  .backBtn {
    border-bottom: 0.5px solid #3a519d;
    padding: 5px 10px;
  }

  .slideFromRight {
    animation: slideFromRight 0.2s linear;
    transition: 1s linear ease-in;
  }

  @keyframes slideFromRight {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .closeEyeIcon {
    right: 20px;
  }
  .selectTag {
    width: 320px;
  }
}

.login-container {
  display: flex;
  align-items: center;
  position: relative;
  top: 1rem;
  right: 3rem;

  .divider {
    width: 0.1rem;
    height: 100%;
    background-color: #c3c3c3;
  }

  span {
    display: inline-block;
    color: black;
    font-weight: bold;
    margin-left: 1rem;
    cursor: pointer;
  }

  button {
    margin-left: 1rem;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 3rem;
    background-color: #ffa32c;
    font-family: inherit;
    font-weight: bold;
    color: white;
  }
}
