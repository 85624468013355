.Responsive {
  display: none;
}

.Web {
  display: block;
}
.magSet {
  // position: "relative" !important;
  left: "125px" !important;
}
.mainDivBook {
  background-color: #f1f1f1;
  width: 100%;
  height: 800px;
  margin-top: 100px;
}
.chooseSubjectsBook {
  /* border: 1px solid #e3e3e3; */
  width: 100%;
  height: auto;
  border-radius: 4px, 4px, 0px, 0px;
  padding-left: 30px;
  background-color: #FFFFFF;
  padding-top: 10px;
  padding-bottom: 20px;
}

.chooseSubjectsBookResp {
  /* border: 1px solid #e3e3e3; */
  // width: 90%;
  height: auto;
  border-radius: 4px;
  padding-left: 30px;
  background-color: #FFFFFF;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 50px;
  position: relative;
  align-items: center;
  //  left: 14px; 
}

.typeNameBook {
  font-weight: 600;
  font-size: 16px;
  position: relative;
  left: -4px;
  margin-top: 10px;
  margin-bottom: 1px;
}

.madeEasy {
  color: #1f1f95;
  font-weight: bold;
  font-size: 23.95px;
  text-align: center;
  font-family: "Raleway", sans-serif;
  padding: 50px 0px 10px 0px;
  line-height: 32px;
}
.paddingClass {
  padding-left: 36px;
}
.bookParagraph {
  text-align: center;
  letter-spacing: 0.2px;
  padding: 0px 190px;
}
.paragraph {
  line-height: 30px;
  color: gray;
  font-family: "Nunito", sans-serif;
  font-size: 17px;
}
.booksDiv {
  margin: 20px;
  margin-top: 70px;
}
.book1 {
  width: 200px;
  height: 250px;
}
.conceptHandbook {
  color: #077c07;
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: bold;
}
.bookDetail1 {
  color: gray;
  text-align: left;
  width: 95%;
  line-height: 27px;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}
.shadowImage {
  width: 390px;
  position: relative;
  right: 200px;
  bottom: 30px;
}

.testSeries {
  background-image: url("../images/sub_back.png") !important;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70px;

  h1 {
    color: #fff;
    font-size: 30px;
    font-weight: 900;
    margin-left: 48px;
    // margin-top: -10px;
    padding-top: 21px;
  }

  h5 {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-left: 48px;
  }
}

.testSeriesResp {
  background-image: url("../images/sub_back.png") !important;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70px;

  h1 {
    color: #fff;
    font-size: 22px;
    font-weight: 900;
    margin-left: 48px;
    // margin-top: -10px;
    padding-top: 21px;
  }

  h5 {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-left: 48px;
  }
}

.checkDiv1 {
  font-size: 13px;
  padding-top: 10px;
  text-align: left;
  width: 273px;
  color: #959090;
  font-weight: 500;
}

.margSecbook4 {
  border: 1px solid lightgray;
  border-radius: 4px;
  // width: 300px;
  height: 370px;
  // margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  background-color: #FFFFFF;
}

// .bookimgdiv {
//   border: 1px solid lightgray;
//   height: 181px;
//   width: 240px;
//   border-radius: 7px;
//   margin-top: 25px;
//   margin-left: 10px;
// }

.masterBook2 {
  width: 93%;
  // height: 113px;
  /* margin-left: 74px; */
  /* margin-top: 18px; */
  border-radius: 4px;
  position: relative;
  left: 10px;
  top: 20px;
}

.cafoundation {
  // line-height: 24.43px;
  text-align: left;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 16px;
  color: #565656;
  // width: 204px;
  /* margin-left: 15px; */
  position: relative;
  top: 28px;
  left: 9px;
  line-height: 20px;
}

.byAmanSir {
  width: 112.89px;
  height: 1px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 13.09px;
  line-height: 19.9px;
  color: #3B63E7;
  /* margin-left: -10px; */
  position: relative;
  top: 15px;
  left: -4px;
}

.rupees {
  width: 70.35px;
  height: 24px;
  font-family: "Roboto", sans-serif;
  font-size: 16.36px;
  font-weight: 600;
  color: #000000;
  margin-left: 10px;
  position: relative;
  top: 18px;
  left: -9px;
}
.border {
  border: 1px solid lightgray;
  width: 292px;
  margin-left: -16px;
}
.bookbtn {
  width: 105%;
  height: 38.45px;
  border-radius: 3.27px;
  background-color: #289028;
  border: none;
  /* margin-top: 15px; */
  /* margin-left: -9px; */
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  /* margin-left: -9px; */
  position: relative;
  top: 25px;
  left: -4px;
}
.bookButton {
  // width: 110.63px;
  height: 19.85px;
  font-family: inter;
  font-weight: 600;
  font-size: 13.09px;
  line-height: 19.9px;
  color: #FFFFFF;
  position: relative;
  // left: 42px;
  top: -1px;
  text-align: center;
}

@media only screen and (max-width: 360px) {
  .margSecbook4 {
    border: 1px solid lightgray;
    border-radius: 4px;
    // width: 300px;
    height: 370px;
    // margin-left: 20px;
    // margin-right: 20px;
    margin: 0px;
    margin-top: 20px;
    background-color: #FFFFFF;
  }
  .madeEasy {
    color: #1f1f95;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    margin-top: 20px;
    padding-top: 50px;
  }
  .showBook {
    display: none;
  }
  .mainDivBook {
    background-color: #f1f1f1;
    width: 100%;
    height: 650px;
    margin-top: 30px;
  }
  .centerBookImg {
    margin-top: -80px;
    border: 1px solid #777;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
  .chooseSubjectsResp {
    border: 1px solid #e3e3e3;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding-left: 25px;
    padding-bottom: 28px;
  }
  .bookDetail1 {
    color: gray;
    text-align: left;
    width: 96%;
    position: absolute;
    left: 10px !important;
    line-height: 20px;
    font-family: "Nunito", sans-serif;
    font-size: 14px !important;
    bottom: -4px;
  }
  .book1 {
    width: 200px;
    height: 250px;
    margin-top: -100px;
  }
}
@media only screen and (max-width: 1000px) {
  .chooseSubjectsResp {
    border: 1px solid #e3e3e3;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding-left: 25px;
    padding-bottom: 28px;
  }
  .madeEasy {
    color: #1f1f95;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    margin-top: 20px;
    padding-top: 50px;
  }
  .showBook {
    display: none;
  }
  .mainDivBook {
    background-color: #f1f1f1;
    width: 100%;
    height: 650px;
    margin-top: 30px;
  }
  .centerBookImg {
    margin-top: -80px;
    border: 1px solid #777;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
  .bookDetail1 {
    color: gray;
    text-align: left;
    width: 96%;
    position: absolute;
    left: 10px !important;
    line-height: 20px;
    font-family: "Nunito", sans-serif;
    font-size: 14px !important;
    bottom: -4px;
  }
  .book1 {
    width: 200px;
    height: 250px;
    margin-top: -100px;
  }

  
}

.books-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0rem 0rem 0rem;
  h1 {
    margin: 0;
    color: black;
    font-size: 2.8rem;
    font-weight: bold;
    font-family: "Raleway", sans-serif;

    span {
      display: inline-block;
      color: #003399;
    }
  }
}

.books-container {
  display: flex;
  padding: 2rem 0rem 2rem 0rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0rem 0rem;

    .divider-line-mob {
      width: 100%;
      height: 3px;
      background-color: white;
    }
  }

  .books-left {
    flex: 1 0 60%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .books-right {
    flex: 1 0 40%;
    background-color: #ebecec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5rem 3rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 2rem 2rem;
      text-align: center;
    }

    span {
      display: inline-block;
      margin: 0;
      font-size: 2.4rem;
      font-weight: 400;
      color: #3a519d;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    h3 {
      width: 45rem;
      margin: 0;
      color: #3a529d;
      margin-top: 1rem;
      font-family: "Ralwway", sans-serif;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        width: 100%;
        display: none;
      }
    }

    p {
      margin: 0;
      color: #616161;
      width: 45rem;
      font-size: 1.6rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: justify;
      }

      &:nth-of-type(1) {
        margin-top: 4rem;

        @media screen and (max-width: 768px) {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (min-width:820px) and (max-width: 1180px) {
  .Responsive {
    display: block;
  }
  
  .Web {
    display: none;
  }
  .testSeriesResp {
    background-image: url("../images/sub_back.png") !important;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 55px;
  
    h1 {
      color: #fff;
      font-size: 15px;
      font-weight: 900;
      margin-left: 48px;
      padding-top: 20px;
    }
  
    h5 {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin-left: 48px;
    }
  }
  .chooseSubjectsResp {
    border: 1px solid #e3e3e3;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding-left: 25px;
    padding-bottom: 28px;
  }
  
}
@media only screen and (min-width: 360px) and (max-width: 768px) {
  .Responsive {
    display: block;
  }
  
  .Web {
    display: none;
  }
  .testSeriesResp {
    background-image: url("../images/sub_back.png") !important;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 55px;
  
    h1 {
      color: #fff;
      font-size: 17px;
      font-weight: 900;
      margin-left: 34px;
      padding-top: 20px;
    }
  
    h5 {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin-left: 48px;
    }
  }

  .testSeriesResp {
    background-image: url("../images/sub_back.png") !important;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 55px;
  }
  
  .chooseSubjectsResp {
    border: 1px solid #e3e3e3;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding-left: 25px;
    padding-bottom: 28px;
  }
  
  .typeNameResp {
    font-size: 15px;
    font-weight: 600;
  }
  .checkDiv1Resp {
    font-size: 13px;
    /* padding-top: 10px; */
    margin-left: -6px;
    margin-bottom: 6px;
  }
  .batch_buttonResp {
    border: none;
    background-color: white;
    font-size: 11px;
  }
  .rightArea {
    margin-top: 35px;
    margin-left: -12px;
  }
  .byAmanSirResp {
    width: 119px;
    height: 20px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 15px;
    line-height: 19.9px;
    color: #3B63E7;
    /* margin-left: 0px; */
  }
  .rupeesResp {
    width: 57px;
    height: 27px;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 600;
    color: #27AB1C;
    margin-left: 74px;
  }
  .borderResp {
    border: 1px solid lightgray;
    width: 292px;
    margin-left: -16px;
    margin-top: 21px;
  }
}

.books-mob-header {
  text-align: center;
  padding-bottom: 1.4rem;

  span {
    display: inline-block;
    margin: 0;
    font-size: 2.2rem;
    font-weight: 400;
    color: #3a519d;
  }

  h3 {
    width: 100%;
    margin: 0;
    color: #3a529d;
    font-size: 2rem;

    span {
      display: inline-block;
      color: #077c07;
    }
  }
}

