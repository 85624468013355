.subjectBack {
  background-color: #000;
  width: 100%;
  height: 100px;
}

.subjectBgImg {
  width: 100% !important;
  height: 100px !important;
  z-index: 9 !important;
  position: relative;
}

.overlaySub {
  position: absolute;
  background-color: #000 !important;
  width: 100% !important;
  height: 100px !important;
  z-index: 10;
  opacity: 0.5;
  background-color: black;
  background-image: linear-gradient(to left, black, black);
}

.attemptMarg {
  position: relative;
  top: 100px;
}

.caTitle {
  color: #fff;
  position: absolute;
  top: 140px;
  left: 85px;
}

.showingCourses {
  position: relative;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  left: 70px;
  top: 20px;
}

.results {
  position: relative;
  top: 30px;
}

.subTitle {
  position: relative;
  top: 23px;
}

.selectArea {
  background-color: #1313a1;
  width: 85%;
  height: 65px;
  border-radius: 5px;
  position: relative;
  top: 23px;
}

.selectAttempt {
  color: #fff;
  font-size: 19px;
  position: relative;
  font-family: "Nunito", sans-serif;
  top: 10px;
  left: 40px;
}

.selectBox {
  position: relative;
  top: 8px;
  left: 65px;
}

.selectAttSub {
  width: 33%;
  height: 45px;
  border-radius: 5px;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  padding: 12px;
}

.cardMain {
  cursor: pointer;
}

.courseCard1 {
  background-color: #5f5fee;
  width: 90%;
  height: 320px;
  border-radius: 5px;
  // position: relative;
  // left: 70px;
}

.courseCard13961 {
  background-color: #5f5fee;
  width: 90%;
  height: 390px;
  border-radius: 5px;
  // position: relative;
  // left: 70px;
}

.courseCard2 {
  background-color: #b17f09;
  width: 90%;
  height: 360px;
  border-radius: 5px;
  // position: relative;
  // left: 28px;
}

.courseCard13962 {
  background-color: #b17f09;
  width: 90%;
  height: 390px;
  border-radius: 5px;
  // position: relative;
  // left: 28px;
}

.courseCard3 {
  background-color: #139413;
  width: 90%;
  height: 360px;
  border-radius: 5px;
  // position: relative;
  // right: 13px
}

.courseCard13963 {
  background-color: #139413;
  width: 90%;
  height: 390px;
  border-radius: 5px;
  // position: relative;
  // right: 13px
}

.courseCard4 {
  background-color: #960c8f;
  width: 90%;
  height: 360px;
  border-radius: 5px;
  // position: relative;
  // right: 55px;
}

.courseCard13964 {
  background-color: #960c8f;
  width: 90%;
  height: 390px;
  border-radius: 5px;
  // position: relative;
  // right: 55px;
}

.cardArea {
  text-align: center;
  width: 91%;
  position: relative;
  left: 65px;
  top: 0px;
}

.courseTitle {
  position: relative;
  top: 25px;
  font-size: 18px;
  color: #fff;
  text-align: left;
  left: 20px;
  border-bottom: 1px solid #fff;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  width: 78%;
  padding-bottom: 12px;
  text-decoration: none;
  height: 55px;
}

.cardSecPart {
  position: relative;
  top: 33px;
  color: #fff;
  width: 85%;
  left: 20px;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
}

.doubtSolving {
  position: relative;
  top: 10px;
}

.personalized {
  position: relative;
  top: 20px;
}

.printedNotes {
  position: relative;
  top: 30px;
}

.liveQuiz {
  position: relative;
  top: 40px;
}

.getDetails {
  background-color: #fff;
  border: none;
  width: 100%;
  height: 43px;
  color: #000;
  position: relative;
  top: 50px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: bold;
}

.subCom {
  font-family: "Nunito", sans-serif;
  position: relative;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 32px;
}

.smartClass {
  color: #fff;
  font-size: 16px;
}

.clickParagraph {
  font-size: 20px;
  line-height: 30px;
}

.divClass {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .getDetails {
    background-color: #fff;
    border: none;
    width: 100%;
    height: 40px;
    color: #373737;
    position: relative;
    top: 65px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
  }

  .cardSecPart {
    position: relative;
    top: 33px;
    color: #fff;
    width: 85%;
    left: 20px;
    text-align: left;
    font-family: "Nunito", sans-serif;
    font-weight: 700;

    h5 {
      font-size: 13px;
      line-height: 1.3rem;
      font-weight: 800;
      margin-top: 0px;
      margin-bottom: 3px;
    }
  }

  .selectArea {
    background-color: #1313a1;
    width: 85%;
    height: 65px;
    border-radius: 5px;
    position: relative;
    top: 23px;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
  }

  .selectAttempt {
    color: #fff;
    font-size: 19px;
    position: relative;
    font-family: "Nunito", sans-serif;
    top: 10px;
    left: 21px;
  }

  .selectAttSub {
    width: 29%;
    height: 45px;
    border-radius: 5px;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    padding: 12px;
  }
}

// newSubjcts
.section1sub {
  background-image: url("../images/detailsBackResp.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70px;

  // height: 150px;

  h1 {
    // font-size: 35px !important;
    // font-weight: bold;
    // padding: 10px;
    // padding-left: 30px;
    // color: #fff;
    // padding-top: 20px;
    color: #fff;
    font-size: 30px;
    font-weight: 900;
    margin-left: 48px;
    // margin-top: -10px;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px) {
  .section1sub {
    background-image: url("../images/detailsBackResp.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
  
    h1 {
      font-size: 25px !important;
      font-weight: bold;
      padding: 10px;
      padding-left: 30px;
      color: #fff;
      padding-top: 20px;
    }
  }
}

.section2 {
  padding-top: 20px;

  h3 {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 25px;
    margin-top: 31px;
  }

  .selectTab {
    background-color: #003399;
    margin-top: 14px;
    border-radius: 5px;
    height: 60px;
    width: 95%;
    padding: 7px;
    text-align: center;

    span {
      color: white;
      font-size: 19px;
      font-weight: 600;
      // color: rgb(231, 230, 230);
    }

    .selectAttSub {
      width: 29%;
      height: 45px;
      border-radius: 5px;
      font-family: "Nunito", sans-serif;
      font-size: 16px;
      padding: 10px;
      margin-left: 31px;
    }
  }
}

.section3 {
  margin-top: 17px;
}

.section4 {
  .combosArea {
    background-color: #41ac79;
    width: 100%;
    height: 170px;
    border-radius: 5px;
    margin-top: 80px;
  }

  .combosArea1 {
    background-color: #41ac79;
    width: 100%;
    height: 55px;
    border-radius: 5px;
    margin-top: 0px;
  }

  .doubtSolvingCom {
    margin-top: 18px;
    color: #fff;
    font-size: 16px;
  }

  .personalizedCom {
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
  }

  .getDetailsCom {
    background-color: #fff;
    border: none;
    width: 90%;
    height: 43px;
    color: #000;
    border-radius: 5px;
    font-size: 17px;
    font-weight: bold;
    margin-top: 43px;

    a {
      color: #5c5c5c;
    }
  }
}

.section5 {
  .clickSubject {
    text-align: center;
    padding-top: 60px;
  }
}

.section6 {
  .bottomText {
    padding-top: 0px;
    text-align: center;
    position: relative;
    top: -25px;

    .marathiText {
      font-weight: 700;
      padding: 40px;
      text-align: center;
    }
  }
}

.paddingContainer {
  padding: 20px 100px 20px 100px;
}

@media screen and (min-width: 360px) and (max-width: 768px) {
  .section2 h3 {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 17px;
    margin-top: 0px;
    text-align: center;
  }

  .paddingContainer {
    padding: 0px 0px 0px 0px;
  }

  .section2 .selectTab .selectAttSub {
    width: 95%;
    height: 39px;
    border-radius: 5px;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    padding: 6px;
    margin-left: 5px;
  }

  .section2 .selectTab {
    background-color: #1313a1;
    margin-top: 14px;
    border-radius: 5px;
    height: auto !important;
    width: 95%;
    padding: 10px;
    margin-left: 10px;
    text-align: center;
  }

  .section4 .combosArea1 {
    background-color: #41ac79;
    width: 100%;
    height: 170px;
    border-radius: 5px;
    margin-left: 1px;
    height: 57px;
  }

  .caTitle {
    color: #fff;
    position: absolute;
    top: 66px;
    left: 10px;
    font-size: 24px;
  }

  .imgHeight {
    height: 60px;
  }

  .showingCourses {
    position: relative;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 20px;
    left: 15px;
    top: 0px;
  }

  .selectArea {
    background-color: #1313a1;
    width: 100%;
    height: 90px;
    border-radius: 5px;
    position: relative;
    top: 23px;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
  }

  .selectAttempt {
    color: #fff;
    font-size: 16px;
    position: relative;
    font-family: "Nunito", sans-serif;
    top: 10px;
    left: 21px;
  }

  .selectAttSub {
    width: 88%;
    height: 37px;
    border-radius: 5px;
    margin-left: -49px;
    margin-top: 4px;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    padding: 7px;
    margin-top: 10px;
  }

  .cardSecPart {
    display: none;
  }

  .cardArea {
    text-align: center;
    width: 100%;
    position: relative;
    left: 0px;
    top: 0px;
  }

  .courseCard13961 {
    background-color: #5f5fee;
    width: 100%;
    height: auto;
    border-radius: 5px;
    // position: relative;
    // left: 70px;
  }

  .attemptMarg {
    position: relative;
    top: 65px;
  }

  .courseCard1 {
    background-color: #5f5fee;
    width: 93%;
    height: 55px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 14px;
    // position: relative;
    // left: 70px;
  }

  .section5 .clickSubject {
    text-align: left;

    padding-top: 60px;
    padding: 18px;
  }

  .clickParagraph {
    font-size: 16px;
    line-height: 22px;
  }

  .container-fluid-Hidden {
    display: none;
  }

  .divClass {
    display: block;
  }

  .subCom {
    color: #fff;
    position: relative;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-top: 15px;

    a {
      color: #fff;
    }
  }

  .subTitle {
    position: relative;
    top: 0px;
  }

  .marathiText {
    font-weight: 700;
    font-size: 24px;
  }

  .bottomText {
    text-align: center;
  }

  .section6 .bottomText .marathiText {
    font-weight: 700;
    padding: 14px;
    text-align: center;
  }

  .courseTitle {
    font-size: 14px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    width: 100%;
    text-decoration: none;
    padding-top: 21px;
    border: none;
    position: relative;
    top: 0px;
    left: 0px;
  }
}

@media screen and (min-width:820px) and (max-width: 1180px) {
  .section2 h3 {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 17px;
    margin-top: 41px;
    text-align: center;
  }

  .paddingContainer {
    padding: 0px 0px 0px 20px;
  }

  .section2 .selectTab .selectAttSub {
    width: 95%;
    height: 39px;
    border-radius: 5px;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    padding: 6px;
    margin-left: 5px;
  }

  .section2 .selectTab {
    background-color: #1313a1;
    margin-top: 14px;
    border-radius: 5px;
    height: auto !important;
    width: 95%;
    padding: 10px;
    margin-left: -26px;
    text-align: center;
  }

  .section4 .combosArea1 {
    background-color: #41ac79;
    width: 744px;
    height: 170px;
    border-radius: 5px;
    margin-left: 1px;
    height: 57px;
  }

  .caTitle {
    color: #fff;
    position: absolute;
    top: 66px;
    left: 10px;
    font-size: 24px;
  }

  .imgHeight {
    height: 60px;
  }

  .showingCourses {
    position: relative;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 20px;
    left: 15px;
    top: 0px;
  }

  .selectArea {
    background-color: #1313a1;
    width: 100%;
    height: 90px;
    border-radius: 5px;
    position: relative;
    top: 23px;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
  }

  .selectAttempt {
    color: #fff;
    font-size: 16px;
    position: relative;
    font-family: "Nunito", sans-serif;
    top: 10px;
    left: 21px;
  }

  .selectAttSub {
    width: 88%;
    height: 37px;
    border-radius: 5px;
    margin-left: -49px;
    margin-top: 4px;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    padding: 7px;
    margin-top: 10px;
  }

  .cardSecPart {
    display: none;
  }

  .cardArea {
    text-align: center;
    width: 100%;
    position: relative;
    left: 0px;
    top: 0px;
  }

  .courseCard13961 {
    background-color: #5f5fee;
    width: 100%;
    height: auto;
    border-radius: 5px;
    // position: relative;
    // left: 70px;
  }

  .attemptMarg {
    position: relative;
    top: 65px;
  }

  .courseCard1 {
    background-color: #5f5fee;
    width: 93%;
    height: 55px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 14px;
    // position: relative;
    // left: 70px;
  }

  .section5 .clickSubject {
    text-align: left;

    padding-top: 60px;
    padding: 18px;
  }

  .clickParagraph {
    font-size: 16px;
    line-height: 22px;
    padding: 0px 50px;
    text-align: center;
    padding-left: 0px;
    margin-left: 23px;
  }

  .container-fluid-Hidden {
    display: none;
  }

  .divClass {
    display: block;
  }

  .subCom {
    color: #fff;
    position: relative;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-top: 15px;

    a {
      color: #fff;
    }
  }

  .subTitle {
    position: relative;
    top: 0px;
  }

  .marathiText {
    font-weight: 700;
    font-size: 24px;
  }

  .bottomText {
    text-align: center;
  }

  .section6 .bottomText .marathiText {
    font-weight: 700;
    padding: 14px;
    text-align: center;
  }

  .courseTitle {
    font-size: 14px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    width: 100%;
    text-decoration: none;
    padding-top: 21px;
    border: none;
    position: relative;
    top: 0px;
    left: 0px;
  }
}
@media screen and (width:768px) {
  .section4 .combosArea1 {
    width: 694px;
  }
  .paddingContainer {
    padding: 0px 0px 0px 20px;
  }
  .section2 h3 {
    margin-top: 50px;
  }
  .section2 .selectTab {
    margin-left: -25px;
  }
}
